import helper from "../../constants/helper";

import {
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  CURRENT_LOGGED_IN_USER_SUCCESS,
  AJAX_CALL_NOTIFICATION_INIT,
  AJAX_CALL_NOTIFICATION_FINISH,
  MARK_AS_READ_NOTIFICATIONS_SUCCESS,
  MARK_AS_READ_NOTIFICATIONS_FAILURE,
  AJAX_CALL_FINSH,
  HANDLE_NOTIFY_TYPE,
  REFRESH_REPORTS_SUCCESS,
} from "../constants/action-types";
import { toast } from "react-toastify";
import Api from "@services/axios";

export const fetchNotifications = () => (dispatch) => {
  dispatch({
    type: AJAX_CALL_NOTIFICATION_INIT,
  });
  Api.FetchNotifications()
    .then((res) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: res.data.data,
      });
      dispatch({
        type: AJAX_CALL_NOTIFICATION_FINISH,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILURE,
        payload: error,
      });
    });
};

export const currentLoggedInUser = (query) => (dispatch) => {
  Api.CurrentLoggedInUser(query)
    .then((res) => {
      dispatch({
        type: CURRENT_LOGGED_IN_USER_SUCCESS,
        payload: res.data.data,
      });

      dispatch({
        type: AJAX_CALL_FINSH,
      });
    })
    .catch(() => {});
};

export const refreshReports = () => async (dispatch) => {
  let headers = {
    headers: helper.headers,
  };
  const params = { main_account: localStorage.getItem("main_account") };

  const json = await Api.RefreshReports(params, headers);
  if (json?.status === 200) {
    dispatch({
      type: REFRESH_REPORTS_SUCCESS,
      payload: json.data,
    });
    dispatch({
      type: AJAX_CALL_FINSH,
    });
  }

  return json;
};

export const markAsReadNotifications = (query) => (dispatch) => {
  let headers = {
    headers: helper.headers,
  };
  dispatch({
    type: AJAX_CALL_NOTIFICATION_INIT,
  });
  Api.MarkAsReadNotifications(query, headers)
    .then((res) => {
      dispatch({
        type: MARK_AS_READ_NOTIFICATIONS_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: AJAX_CALL_NOTIFICATION_FINISH,
      });
      dispatch(fetchNotifications());
      dispatch(currentLoggedInUser("brand"));
      if (!query.flag) {
        toast.success(helper.successMsg);
      }
    })
    .catch((error) => {
      dispatch({
        type: MARK_AS_READ_NOTIFICATIONS_FAILURE,
        payload: error,
      });
    });
};

export const brandNotifyPush = (query, navigate, ownProps) => (dispatch) => {
  if (query.type === "/brand/setting-brand-invitation") {
    ownProps.history.push(query.type);
  } else {
    dispatch({
      type: HANDLE_NOTIFY_TYPE,
      payload: query.type,
    });
    navigate("/brand/brand-booking/" + query.id);
  }
};
