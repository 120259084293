import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Btrendy from "../../constants/Btrendy";
import store from "@store/index";

export default function AutoLoginScreen() {
  const [loader, setLoader] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const sendAutoLoginRequest = async () => {
      setLoader(true);
      const json = await Btrendy.autoLogin({ id: id });
      if (json.status === 200) {
        store.dispatch({
          type: "HANDLE_BRAND_LOGIN_SUCCESS",
          payload: json.data,
        });
      }
      setLoader(false);
    };
    sendAutoLoginRequest();
  }, [id]);

  return (
    <div class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        {loader && (
          <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Checking request...
          </h1>
        )}
      </div>
    </div>
  );
}
