import { Component } from "react";
import CheckMark from "@assets/svgs/check.webp";

class AppsumoFeatures extends Component {
  render() {
    const { refreshData } = this.props;
    return (
      <>
        <b className="text-white">Features Included in Plan</b>
        <ul className="pl-3">
        <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                  <b className="mr-1">
                    {refreshData &&
                    refreshData.offer &&
                    refreshData.offer.credits
                      ? refreshData.offer.credits
                      : 0}
                  </b>{" "}
                  Credits per month
                </p>
              </span>
            </div>
          </li>
        <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                <b className="mr-1">300M+</b> influencers on Instagram, Tiktok, Youtube
                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Discover influencers
                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Analyze demographics and statistics

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Fraud prevention

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Built-in CRM

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Automate outreach

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Integrate Gmail or custom SMTP

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Track mentions on Instagram and Tiktok

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                User management

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Credit allocations across workspaces

                </p>
              </span>
            </div>
          </li>
          <li className="flex mt-4">
            <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
            <div>
              <span>
                <p className="ml-4 text-white">
                Export Influencers to CSV, XLS, JSON

                </p>
              </span>
            </div>
          </li>
          {refreshData && refreshData.offer && refreshData.offer.team_member>0 &&
            <li className="flex mt-4">
              <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
              <div>
                <span>
                  <p className="ml-4 text-white">
                    {refreshData.offer.team_member} team members
                  </p>
                </span>
              </div>
            </li>
          }
          {refreshData && refreshData.offer && refreshData.offer.sub_account>0 &&
            <li className="flex mt-4">
              <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
              <div>
                <span>
                  <p className="ml-4 text-white">
                   {refreshData.offer.sub_account} workspaces
                  </p>
                </span>
              </div>
            </li>
          }
          {refreshData && refreshData.offer && refreshData.offer.team_member==-1 &&
            <li className="flex mt-4">
              <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
              <div>
                <span>
                  <p className="ml-4 text-white">
                  Unlimited team members
                  </p>
                </span>
              </div>
            </li>
          }
          {refreshData && refreshData.offer && refreshData.offer.sub_account==-1 &&
            <li className="flex mt-4">
              <img src={CheckMark} className="w-[15px] h-[11.5px]" alt="check" />
              <div>
                <span>
                  <p className="ml-4 text-white">
                  Unlimited workspaces
                  </p>
                </span>
              </div>
            </li>
          }
        </ul>
      </>
    );
  }
}

export default AppsumoFeatures;
