import SettingBrandCredentials from "@container/brands/SettingBrandCredentials";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export default function BrandSettingCredentialsScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();

  return (
    <SettingBrandCredentials
      navigate={navigate}
      params={params}
      search={search}
    />
  );
}
