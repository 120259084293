import React, { useState } from "react";
import { useSelector } from "react-redux";
import avatar from "@assets/avatar.webp";
import { FaCloudDownloadAlt, FaSpinner } from "react-icons/fa";
import moment from "moment";
import { FcLike } from "react-icons/fc";
import { MdComment, MdPeopleAlt } from "react-icons/md";
import ProxyImage from "@components/ProxyImage";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Pagination from "@components/Pagination";
import Loader from "@components/global/Loader";
import { useDispatch } from "react-redux";
import { actions } from "@store/redux/BrandReportsRedux";
import SimpleSlider from "./Slider";
import ContentDisplay from "./ContentDisplay";
import CustomLoader from "@components/global/CustomLoader";

const ContentCards = () => {
  const [currentSlide, setCurrentSlide] = useState(null);
  const dispatch = useDispatch();
  const report = useSelector((state) => state.BrandReports.currentReportDetail);
  const searchQuery = useSelector((state) => state.BrandReports.searchQuery);
  const contentDetail = useSelector(
    (state) => state.BrandReports.reportPublishedContentDetail
  );
  const isContentLoading = useSelector(
    (state) => state.BrandReports.isContentLoading
  );
  const loadMore = useSelector(
    (state) => state.BrandReports.isContentLoadMoreLoading
  );

  const formatedNumber = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  };

  const onPageChanged = async (pageData) => {
    const query = Object.assign({}, searchQuery);
    query["page"] = pageData.currentPage;
    await actions.fetchContents(dispatch, report.id, query);
    window.scrollTo(0, 0);
  };

  const groupedPosts = (contentDetail.data || []).reduce((groups, content) => {
    const date = moment(content.published_at).format("YYYY-MM-DD");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(content);
    return groups;
  }, {});

  const getS3ContentPath = (content) => {
    let path;
    if (content.is_s3 && report.tracking_platform !== "tiktok") {
      const baseUrl = process.env.REACT_APP_BTRENDY_WEB_CONTENT + "/";
      if ("s3_path" in content) {
        path = baseUrl + content.s3_path;
      } else {
        path = baseUrl + (content.s3_video_path || content.s3_image_path);
      }
    } else {
      path = content.media_url || content.media_pic;
    }
    return path;
  };

  if (isContentLoading) {
    return (
      <div className="flex items-center relative justify-center h-[28vh]">
        <CustomLoader />
      </div>
    );
  }

  return (
    <>
      {(contentDetail.data || []).length > 0 ? (
        searchQuery.sortQuery === "Date" ? (
          Object.keys(groupedPosts).map((date) => (
            <div key={date}>
              <p className="text-gray-600 mb-3">
                {moment(date).format("MMM DD, YYYY")} (
                {groupedPosts[date].length})
              </p>
              <div className="grid grid-cols-12 gap-x-8 gap-y-10">
                {groupedPosts[date].map((content) => (
                  <div
                    className="border bg-white space-y-5 xl:col-span-3 lg:col-span-4 md:col-span-6 col-span-12 w-full rounded-[8px] shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] p-5 relative"
                    key={content.id}
                  >
                    <div className="flex justify-between flex-wrap items-center">
                      <div className="flex items-center gap-x-1">
                        {content.media_user.s3_path ? (
                          <img
                            className="rounded-full w-10 h-10"
                            src={`${process.env.REACT_APP_BTRENDY_WEB_CONTENT}/${content.media_user.s3_path}`}
                            alt=""
                          />
                        ) : (
                          <ProxyImage
                            className="rounded-full w-11 h-11"
                            alt=""
                            url={content.media_user.profile_pic || avatar}
                            addCancelToken={() => {}}
                          />
                        )}
                        <div className="truncate">
                          <span className="text-purple-900 truncate">
                            @{content.media_user.username}
                          </span>
                          <p className="text-gray-400 text-[12px] capitalize font-medium pl-1">
                            {content.media_type}
                          </p>
                        </div>
                      </div>
                      <Link
                        to={
                          (content.batch_medias || []).length > 0 &&
                          currentSlide
                            ? getS3ContentPath(currentSlide)
                            : getS3ContentPath(content)
                        }
                        target="_blank"
                        className="border border-purple-900 py-3 px-5 rounded-[8px] bg-white hover:bg-purple-800 cursor-pointer text-purple-800 hover:text-white"
                      >
                        <FaCloudDownloadAlt size={18} />
                      </Link>
                    </div>
                    {(content.batch_medias || []).length > 0 ? (
                      <SimpleSlider
                        batch={content.batch_medias}
                        mediaUser={content.media_user.profile_pic}
                        setCurrentSlide={setCurrentSlide}
                      />
                    ) : (
                      <ContentDisplay content={content} />
                    )}
                    <p className="text-gray-400 text-[12px] font-medium">
                      {moment(content.published_at).format("MMM DD, YYYY")}
                    </p>
                    <p
                      className="text-ellipsis overflow-hidden h-[41px]"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {content.caption}
                    </p>
                    {content.media_type !== "story" && (
                      <div className="flex justify-between">
                        <span className="flex gap-x-2 items-center">
                          <FcLike size={18} />
                          {content.likes >= 0
                            ? formatedNumber(content.likes)
                            : content.likes < 0
                            ? 0
                            : "-"}
                        </span>
                        <span className="flex gap-x-2 items-center">
                          <MdComment size={18} className="text-blue-500" />
                          {content.comments
                            ? formatedNumber(content.comments)
                            : "-"}
                        </span>
                        {content.shares ? (
                          <span className="flex gap-x-2 items-center">
                            <MdPeopleAlt size={18} color="pink" />
                            {content.shares
                              ? formatedNumber(content.shares)
                              : "-"}
                          </span>
                        ) : (
                          ""
                        )}
                        {content.views ? (
                          <span className="flex gap-x-2 items-center">
                            <BsEyeFill size={18} color="pink" />
                            {content.views
                              ? formatedNumber(content.views)
                              : "-"}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="grid grid-cols-12 gap-x-8 gap-y-10">
            {contentDetail.data.map((content) => (
              <div
                className="border bg-white space-y-5 xl:col-span-3 lg:col-span-4 md:col-span-6 col-span-12 w-full rounded-[8px] shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] p-5 relative"
                key={content.id}
              >
                <div className="flex justify-between flex-wrap items-center">
                  <div className="flex items-center gap-x-1">
                    {content.media_user.s3_path ? (
                      <img
                        className="rounded-full w-10 h-10"
                        src={`${process.env.REACT_APP_BTRENDY_WEB_CONTENT}/${content.media_user.s3_path}`}
                        alt=""
                      />
                    ) : (
                      <ProxyImage
                        className="rounded-full w-11 h-11"
                        alt=""
                        url={content.media_user.profile_pic || avatar}
                        addCancelToken={() => {}}
                      />
                    )}
                    <div className="truncate">
                      <span className="text-purple-900 truncate">
                        @{content.media_user.username}
                      </span>
                      <p className="text-gray-400 text-[12px] capitalize font-medium pl-1">
                        {content.media_type}
                      </p>
                    </div>
                  </div>
                  <Link
                    to={
                      (content.batch_medias || []).length > 0 && currentSlide
                        ? getS3ContentPath(currentSlide)
                        : getS3ContentPath(content)
                    }
                    target="_blank"
                    className="border border-purple-900 py-3 px-5 rounded-[8px] bg-white hover:bg-purple-800 cursor-pointer text-purple-800 hover:text-white"
                  >
                    <FaCloudDownloadAlt size={18} />
                  </Link>
                </div>
                {(content.batch_medias || []).length > 0 ? (
                  <SimpleSlider
                    batch={content.batch_medias}
                    mediaUser={content.media_user.profile_pic}
                    setCurrentSlide={setCurrentSlide}
                  />
                ) : (
                  <ContentDisplay content={content} />
                )}
                <p className="text-gray-400 text-[12px] font-medium">
                  {moment(content.published_at).format("MMM DD, YYYY")}
                </p>
                <p
                  className="text-ellipsis overflow-hidden h-[41px]"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {content.caption}
                </p>
                {content.media_type !== "story" && (
                  <div className="flex justify-between">
                    <span className="flex gap-x-2 items-center">
                      <FcLike size={18} />
                      {content.likes >= 0
                        ? formatedNumber(content.likes)
                        : content.likes < 0
                        ? 0
                        : "-"}
                    </span>
                    <span className="flex gap-x-2 items-center">
                      <MdComment size={18} className="text-blue-500" />
                      {content.comments
                        ? formatedNumber(content.comments)
                        : "-"}
                    </span>
                    {content.shares ? (
                      <span className="flex gap-x-2 items-center">
                        <MdPeopleAlt size={18} color="pink" />
                        {content.shares ? formatedNumber(content.shares) : "-"}
                      </span>
                    ) : (
                      ""
                    )}
                    {content.views ? (
                      <span className="flex gap-x-2 items-center">
                        <BsEyeFill size={18} color="pink" />
                        {content.views ? formatedNumber(content.views) : "-"}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        <div className="text-center col-span-4 w-full py-[4.2rem] px-[1rem] justify-center text-[#bbb] text-[18px] font-medium leading-[40px]">
          We have nothing to show you here.
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex-grow"></div>
        <div className="inline-flex items-center">
          {!isContentLoading && (contentDetail.total || 0) > 10 && (
            <Pagination
              totalRecords={Math.min(contentDetail.total || 0, 10000)}
              pageLimit={10}
              pageNeighbours={10}
              onPageChanged={onPageChanged}
            />
          )}
          {contentDetail &&
            !isContentLoading &&
            (contentDetail.data || []).length > 0 &&
            loadMore && <Loader size="20" />}
        </div>
      </div>
    </>
  );
};

export default ContentCards;
