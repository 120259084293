import { Component } from "react";
import TopHeader from "@components/BrandInfluencerList/TopHeader";
import Filters from "@components/BrandInfluencerList/Filters";
import Lists from "@components/BrandInfluencerList/Lists";
import SubHeader from "@components/Header/SubHeader";

class BrandInfluencersTiktokListScreeen extends Component {
    render() {
        return (
            <>
                <SubHeader title={"List Influencers Tiktok"} />
                <div className="pb-12">
                    <div className="py-[20px] mb-0">
                        <TopHeader />
                        <div className="bg-[#ddd] h-[1px] w-full my-6" />
                        <Filters />
                    </div>
                    <div className="pt-12">
                        <Lists />
                    </div>
                </div>
            </>
        );
    }
}

export default BrandInfluencersTiktokListScreeen;
