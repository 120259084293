import { Menu, Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react'
import { FiChevronRight, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import avatar from "@assets/avatar.webp";
import Help from "@assets/life_saver.webp";
import Influencer from "@assets/influencer.webp";
import Follower from "@assets/follower.webp";
import Explorer from "@assets/explorer.webp";
import Assets from "@assets/assets.webp";
import InflDiscover from "@assets/infl_discover.webp";
import InflAnalyzer from "@assets/infl_analyzer.webp";
import Youtube from "@assets/youtube.webp";
import Tiktok from "@assets/tiktok.webp";
import Email from "@assets/email.webp";
import Lists from "@assets/list.webp";
import Product from "@assets/product.webp";
import AddBrand from './AddBrand';
import { HANDLE_LOGOUT_SUBMIT } from "@store/reducers/LoginReducer";
import Notification from '@components/Notification';
import { MdLock } from 'react-icons/md';
import Tooltip from '@components/global/Tooltip';
import { HiOutlineMenu } from 'react-icons/hi';

const SubHeader = (props) => {

    const { refreshData, subAccounts, mainAccount } = props;
    const addbrandRef = React.createRef();

    const navigate = useNavigate();
    const switchAccount = (id) => {
        const data = {
          id: id,
          main_account: localStorage.getItem("main_account"),
        };
       props.switchAccount(data);
    }

    return (
        <> 
            <header className="rounded-md relative w-full flex flex-wrap items-center justify-between z-10 transition-all duration-200 ease-in p-5">
                <div className="flex items-center gap-3 flex-wrap w-full justify-end">  
                    <div>
                        <h1 className='text-white font-semibold text-4xl'> {props.title ? props.title : `👋 Hello ${props.currentLoggedUser?.first_name ?? props.currentLoggedUser?.name}`}</h1>
                    </div>       
                    <div className="xxs:space-x-5 space-x-3 flex ml-auto items-center shrink-0">
                        <Popover className="bg-white rounded-full hidden items-center relative">
                            <Popover.Button className="bg-white p-1 rounded-md inline-flex items-center justify-center">
                                <img
                                    src={Help}
                                    className="cursor-pointer shrink-0 w-[20px] h-[20px]"
                                    alt="help"
                                />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel className="absolute top-full right-0 z-10 mt-4 w-screen transform sm:translate-x-0 xs:translate-x-[100px] translate-x-[150px] sm:!px-0 max-w-[300px]">
                                    <div className="rounded-[8px] shadow-lg bg-bgBodyLight divide-y-2 divide-gray-50 py-3">
                                        <p className="font-medium text-white text-[20px] pb-2 pt-2 px-4 flex justify-between">
                                            Help & Support
                                        </p>
                                        <Popover.Button
                                            as="div"
                                            className="hover:bg-gray-50 py-[10px] border-0 text-white hover:black"
                                        >
                                            <a
                                                target="_blank"
                                                href="https://roadmap.btrendy.co/"
                                                rel="noopener noreferrer"
                                                className="  px-4 flex justify-between "
                                            >
                                                Request a feature
                                                <FiChevronRight size={20} className=" " />
                                            </a>
                                        </Popover.Button>
                                        <Popover.Button
                                            as="div"
                                            className="hover:bg-gray-50 py-[0.5rem] text-white hover:black "
                                        >
                                            <a
                                                target="_blank"
                                                href="https://roadmap.btrendy.co/roadmap"
                                                rel="noopener noreferrer"
                                                className="px-4 flex justify-between "
                                            >
                                                Product roadmap
                                                <FiChevronRight
                                                    size={20}
                                                    className=""
                                                />
                                            </a>
                                        </Popover.Button>
                                        <Popover.Button
                                            as="div"
                                            className="hover:bg-gray-50 py-[10px] border-b border-[#0000001f] text-white hover:black"
                                        >
                                            <a
                                                target="_blank"
                                                href="https://knowledgebase.btrendy.co/docs"
                                                rel="noopener noreferrer"
                                                className=" px-4 flex justify-between "
                                            >
                                                Knowledge base
                                                <FiChevronRight
                                                    size={20}
                                                    className=""
                                                />
                                            </a>
                                        </Popover.Button>
                                        <Popover.Button
                                            as="div"
                                            className="hover:bg-gray-50 py-[10px] text-white hover:black"
                                        >
                                            <a
                                                target="_blank"
                                                href="https://calendly.com/hazim-klafla/discovery"
                                                rel="noopener noreferrer"
                                                className=" px-4 flex justify-between "
                                            >
                                                Talk to the founder
                                                <FiChevronRight
                                                    size={20}
                                                    className=""
                                                />
                                            </a>
                                        </Popover.Button>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                        <Notification navigate={navigate} />

                        <Popover className="bg-white rounded-full lg:hidden flex items-center relative">
                            <Popover.Button className="bg-white p-1 rounded-full border-2 border-primary inline-flex items-center focus-visible:outline-0 justify-center">
                                <img
                                    className="logo rounded-full w-[32px] h-[32px]"
                                    alt="logo"
                                    src={
                                        props.currentLoggedUser &&
                                            props.currentLoggedUser.avatar
                                            ? props.currentLoggedUser.avatar
                                            : avatar
                                    }
                                />
                            </Popover.Button>
                            <Transition
                                as={React.Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel className="absolute top-full right-0 text-white rounded-[8px] z-10 mt-4 w-screen transform sm:translate-x-0 translate-x-[80px] lg:max-w-[38rem] md:max-w-[560px] sm:max-w-[450px] max-w-[320px] py-[0.5rem] border">
                                    {({ close }) => (
                                        <div className="rounded-[8px] bg-bgBody py-3 ">
                                            <div className="sm:flex flex-wrap px-1 gap-3 bg-bgBody">
                                                <div className="pl-2">
                                                    <img
                                                        className="border-2 rounded-full border-primary p-1 w-[62px]"
                                                        alt="logo"
                                                        src={
                                                            props.currentLoggedUser.avatar
                                                                ? props.currentLoggedUser.avatar
                                                                : avatar
                                                        }
                                                    />
                                                </div>
                                                <div className="my-auto md:w-1/3">
                                                    <div>
                                                        <Tooltip
                                                            trigger={
                                                                <h4 className="text-white text-[18px] truncate font-medium">
                                                                    {props.currentLoggedUser &&
                                                                        props.currentLoggedUser.name
                                                                        ? props.currentLoggedUser.name
                                                                        : ""}
                                                                </h4>
                                                            }
                                                            tooltipText={
                                                                props.currentLoggedUser &&
                                                                    props.currentLoggedUser.name
                                                                    ? props.currentLoggedUser.name
                                                                    : ""
                                                            }
                                                            placement="top-left"
                                                        />
                                                        <span className="text-[12px] lightDark">
                                                            {props.currentLoggedUser &&
                                                                props.currentLoggedUser.city
                                                                ? props.currentLoggedUser.city[
                                                                "label"
                                                                ] + ","
                                                                : ""}
                                                            {props.currentLoggedUser &&
                                                                props.currentLoggedUser.country
                                                                ? props.currentLoggedUser.country[
                                                                "label"
                                                                ]
                                                                : ""}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="ml-auto md:w-2/5">
                                                    <p>Plan Name: {props.planName}</p>
                                                </div>
                                            </div>

                                            <div className="sm:flex gap-x-5 mt-4 mb-2 px-[1rem]">
                                                <div className="my-auto  md:w-1/3">
                                                    <p className="text-white text-[12px]">Main Account</p>
                                                </div>
                                                <div className="md:w-4/6">
                                                    <div className="flex gap-x-5 flex-wrap items-center sm:justify-around justify-end">
                                                        <button
                                                            onClick={close}
                                                            className="bg-transparent p-0 w-auto inline-block border-0"
                                                        >
                                                            <NavLink
                                                                to="/account"
                                                                className="text-white hover:success"
                                                            >
                                                                <u className="text-[14px] mb-1 inline-block">
                                                                    Account
                                                                </u>
                                                            </NavLink>
                                                        </button>
                                                        {refreshData.is_main &&
                                                            refreshData.planName !== "Free" && (
                                                                <button
                                                                    onClick={close}
                                                                    className="bg-transparent p-0 w-auto inline-block"
                                                                >
                                                                    <NavLink
                                                                        to="/billing/credit"
                                                                        className="text-white hover:success"
                                                                    >
                                                                        <u className="text-[14px] mb-1 inline-block">
                                                                            Purchase credits
                                                                        </u>
                                                                    </NavLink>
                                                                </button>
                                                            )}
                                                        {refreshData &&
                                                            refreshData.is_main &&
                                                            !refreshData.is_appsumo ? (
                                                            <button
                                                                onClick={close}
                                                                className="bg-transparent p-0 w-auto inline-block"
                                                            >
                                                                <NavLink
                                                                    className="mt-4 text-white hover:success"
                                                                    to="/billing"
                                                                >
                                                                    <u className="text-[14px] mb-1 inline-block">
                                                                        Upgrade
                                                                    </u>
                                                                </NavLink>
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="max-h-[200px] overflow-y-auto">
                                                {mainAccount && mainAccount.id && (
                                                    <div
                                                        className={`flex items-center border-b py-3 px-[1rem] ${props.user.id === mainAccount.id &&
                                                            "bg-bgBody"
                                                            }`}
                                                    >
                                                        <img
                                                            className="mr-4 object-cover rounded-full w-[38px] h-[38px]"
                                                            src={
                                                                refreshData.is_main
                                                                    ? props.currentLoggedUser.avatar ||
                                                                    avatar
                                                                    : mainAccount.profile_pic
                                                                        ? process.env.REACT_APP_AWS_URl +
                                                                        "/" +
                                                                        mainAccount.profile_pic
                                                                        : avatar
                                                            }
                                                            alt={mainAccount.name}
                                                        />
                                                        <div>
                                                            <h6 className="font-medium text-[13px] mb-1">
                                                                {refreshData.is_main
                                                                    ? props.currentLoggedUser.name
                                                                    : mainAccount.name}
                                                            </h6>
                                                        </div>
                                                        {props.user.id !== mainAccount.id ? (
                                                            <button
                                                                className="px-[1rem] inline-flex items-center justify-center whitespace-nowrap rounded-[8px] border-0 text-[12px] bg-primary font-medium text-white hover:opacity-80 h-[35px] tracking-wider ml-auto"
                                                                onClick={() =>
                                                                    switchAccount(mainAccount.id)
                                                                }
                                                            >
                                                                Switch
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {subAccounts && subAccounts.length > 0 && (
                                                    <div className="py-3">
                                                        <p className="text-white text-[12px] ml-4">Brands</p>
                                                    </div>
                                                )}
                                                {subAccounts &&
                                                    subAccounts.length > 0 &&
                                                    subAccounts.map((account, key) => (
                                                        <Popover.Button
                                                            key={key}
                                                            as="div"
                                                            className={`bg-transparent p-0 w-full inline-block`}
                                                        >
                                                            <div
                                                                className={`flex items-center border-bottom list-gray py-3 px-[1rem] ${props.user.id === account.id &&
                                                                    "bg-active"
                                                                    }`}
                                                            >
                                                                <img
                                                                    className="mr-4 object-cover rounded-full w-[38px] h-[38px]"
                                                                    src={
                                                                        account.profile_pic
                                                                            ? process.env.REACT_APP_AWS_URl +
                                                                            "/" +
                                                                            account.profile_pic
                                                                            : avatar
                                                                    }
                                                                    alt={account.name}
                                                                />
                                                                <div>
                                                                    <h6 className="font-medium text-[13px] mb-1">
                                                                        {account.name}
                                                                    </h6>
                                                                </div>
                                                                {props.user.id !== account.id ? (
                                                                    <button
                                                                        className="px-[1rem] leading-7 border-0 text-[14px] rounded-[8px] bg-primary font-medium text-white hover:opacity-80 whitespace-nowrap tracking-wider h-[35px] inlie-flex item-center justify-center ml-auto"
                                                                        onClick={() =>
                                                                            switchAccount(account.id)
                                                                        }
                                                                    >
                                                                        Switch
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                        </Popover.Button>
                                                    ))}
                                            </div>

                                            <div
                                                className={`flex items-center ${refreshData.is_main
                                                        ? "justify-between"
                                                        : "justify-end"
                                                    }  mt-4 px-[1rem]`}
                                            >
                                                {refreshData.is_main &&
                                                    ((refreshData.offer?.sub_account > 0 || refreshData.offer?.sub_account == -1) ? (
                                                        <button
                                                            className="px-4 py-[0.5rem] leading-7 border-0 text-[14px] rounded-[8px] bg-primary font-medium text-white hover:opacity-80 whitespace-nowrap h-[40px]"
                                                            onClick={() =>
                                                                addbrandRef.current.openAddbrand()
                                                            }
                                                        >
                                                            Add Brand
                                                        </button>
                                                    ) : (
                                                        <Popover.Button
                                                            as="div"
                                                            className="bg-transparent p-0 w-auto inline-block"
                                                        >
                                                            {props.role === "brand" && (
                                                                <Tooltip
                                                                    trigger={
                                                                        <NavLink
                                                                            className="px-4 py-[0.5rem] leading-6 bg-[#f4f4f5] border-[1px] dark border-[#f4f4f5] shadow inline-flex items-center justify-center whitespace-nowrap text-[12px] font-medium hover:opacity-80"
                                                                            to="/billing"
                                                                        >
                                                                            <FiPlus size={16} className="mr-1" />
                                                                            Add sub-account
                                                                            <MdLock className="ml-1" size={16} />
                                                                        </NavLink>
                                                                    }
                                                                    tooltipText="Please upgrade your subscription to add
                                                                                sub-accounts"
                                                                    placement="top-left"
                                                                />
                                                            )}
                                                        </Popover.Button>
                                                    ))}
                                                <Popover.Button
                                                    as="div"
                                                    className="bg-transparent p-0 inline-block w-auto"
                                                >
                                                    <button
                                                        className="px-4 py-[0.5rem] leading-7 border-0 text-[14px] rounded-[8px] bg-primary font-medium text-white hover:opacity-80 whitespace-nowrap h-[40px]"
                                                        onClick={props.handleLogoutSubmit}
                                                    >
                                                        Log out
                                                    </button>
                                                </Popover.Button>
                                            </div>
                                        </div>
                                    )}
                                </Popover.Panel>
                            </Transition>
                        </Popover>

                        <Menu
                            as="div"
                            className="hidden"
                            id="preserve_scroll"
                        >
                            <Menu.Button className="bg-transparent rounded-md p-2 hover:text-white inline-flex items-center justify-center text-gray-500 focus-visible:outline-0 hover:bg-gray-700">
                                <HiOutlineMenu size={30} className="text-white" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute top-20 z-2xlfull inset-x-0 py-[0.5rem] transition transform origin-top-right xl:hidden">
                                    <div className="px-1 py-1 ">
                                        <div className="rounded-[8px] shadow-lg bg-bgBodyDark">
                                            <div className="py-8 px-12 space-y-10">
                                                <Popover className="relative">
                                                    <Popover.Button className="relative cursor-pointer">
                                                        <div className="inline-flex items-center">
                                                            <img
                                                                src={Influencer}
                                                                alt="Influencer"
                                                                className="overflow-hidden invert mr-1 shrink-0 w-[16px] h-[16px]"
                                                            />
                                                            <span className="font-normal text-white">
                                                                Influencers
                                                            </span>
                                                        </div>
                                                    </Popover.Button>
                                                    <Popover.Panel className="absolute z-10 mt-4 max-h-[300px] overflow-y-auto md:w-screen md:max-w-[675px] md:inset-x-auto inset-x-0 transform px-2 sm:!px-1 lg:!ml-0 -translate-x-1">
                                                        <div className="overflow-hidden rounded-[8px] shadow-lg ring-1 ring-white ring-opacity-5">
                                                            <div className="grid md:grid-cols-2 grid-cols-1 ">
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/discovery/instagram"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={InflDiscover}
                                                                                    alt="Discover"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Discover
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Access the full power of our 35M
                                                                                    inflencers database search
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/list/instagram"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={Lists}
                                                                                    alt="Lists"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Lists
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Save Influencers into lists and
                                                                                    estimate audience overlap
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/past-report/instagram"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={InflAnalyzer}
                                                                                    alt="Analyzer"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Analyzer
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Analyze influencers for fake
                                                                                    followers and more
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Popover>
                                                <Popover className="relative">
                                                    <Popover.Button className="relative cursor-pointer">
                                                        <div className="inline-flex items-center">
                                                            <img
                                                                src={Follower}
                                                                alt="Influential Follower"
                                                                className="overflow-hidden invert mr-1 shrink-0 w-[16px] h-[16px]"
                                                            />
                                                            <span className="font-normal text-white">
                                                                Influential Fans
                                                            </span>
                                                        </div>
                                                    </Popover.Button>
                                                    <Popover.Panel className="absolute z-10  mt-4 max-h-[300px] overflow-y-auto  md:w-screen md:max-w-[675px] md:inset-x-auto inset-x-0 transform px-2 sm:!px-1 lg:!ml-0 -translate-x-1">
                                                        <div className="overflow-hidden rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 bg-bgBodyLight">
                                                            <div className="grid md:grid-cols-2 grid-cols-1 ">
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/influencial/tiktok"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={Tiktok}
                                                                                    alt="Tiktok"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Tiktok Fans
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Discover your Tiktok influential
                                                                                    followers, likers and mentions
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/influencial/youtube"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={Youtube}
                                                                                    alt="Youtube"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Youtube Fans
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Discover your Youtube influential
                                                                                    followers, likers and mentions
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            to="/influencial/email-match"
                                                                            className={({ isActive }) => [
                                                                                "-m-3 flex items-start rounded-[8px] p-3  ",
                                                                                isActive
                                                                                    ? " hover:bg-blue-50"
                                                                                    : " hover:bg-gray-50",
                                                                            ]}
                                                                        >
                                                                            <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                                <img
                                                                                    src={Email}
                                                                                    alt="Email"
                                                                                    className="overflow-hidden invert w-[19px] h-[19px]"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <p className="text-[14px] font-medium text-white">
                                                                                    Email Match
                                                                                </p>
                                                                                <p className="mt-1 text-sm text-white">
                                                                                    Discover Influencers among your
                                                                                    own email list
                                                                                </p>
                                                                            </div>
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Popover>

                                                <Menu.Item>
                                                    <NavLink
                                                        to="/reports"
                                                        className="text-white inline-flex items-center rounded-md text-[14px] font-normal  focus:outline-none"
                                                    >
                                                        <img
                                                            src={Explorer}
                                                            alt="Explorer"
                                                            className="overflow-hidden invert mr-1 shrink-0 w-[16px] h-[16px]"
                                                        />
                                                        Reports & Tracking
                                                    </NavLink>
                                                </Menu.Item>
                                                <Popover className="relative">
                                                    <Popover.Button className="relative cursor-pointer">
                                                        <div className="inline-flex items-center">
                                                            <img
                                                                src={Assets}
                                                                alt="Assets"
                                                                className="overflow-hidden invert mr-1 shrink-0 w-[16px] h-[16px]"
                                                            />
                                                            <span className="font-normal text-white">Assets</span>
                                                        </div>
                                                    </Popover.Button>
                                                    <Popover.Panel className="absolute z-10  mt-4 max-h-[300px] overflow-y-auto md:w-screen md:max-w-[300px] md:inset-x-auto inset-x-0 transform px-2 sm:!px-1 lg:!ml-0 -translate-x-1">
                                                        <div className="overflow-hidden rounded-[8px] shadow-lg bg-bgBodyLight">
                                                            <div className="flex">
                                                                <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                                                                    <NavLink
                                                                        to="/products"
                                                                        className={"m-3 flex items-start rounded-[8px] p-3"}
                                                                    >
                                                                        <div className="h-[40px] w-[40px] rounded flex items-center justify-center border-[1px] border-[#dee2e6] shrink-0 mr-4">
                                                                            <img
                                                                                src={Product}
                                                                                alt="Product"
                                                                                className="overflow-hidden invert w-[19px] h-[19px]"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-[14px] font-medium text-white">
                                                                                Products
                                                                            </p>
                                                                            <p className="mt-1 text-sm text-gray-500">
                                                                                Check your current assets
                                                                            </p>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Popover>

                                                <Menu.Item>
                                                    <Link
                                                        to={
                                                            refreshData.is_main
                                                                ? "/billing/credit"
                                                                : "/history/credits-history"
                                                        }
                                                        className="ml-0 gap-x-1 flex items-center justify-center bg-primary px-4 py-[5px] rounded-full"
                                                    >
                                                        <span className="text-white text-[13px] font-normal">
                                                            Credits remaining:
                                                        </span>
                                                        <span className="text-white text-[13px] font-medium">
                                                            {parseInt(props.remainingCredits)}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>
                                            </div>
                                        </div>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
             </header>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        user: localStorage.getItem("isLogin") ? JSON.parse(localStorage.user) : "",
        role: localStorage.role,
        currentLoggedUser: state.HeaderReducer.currentLoggedUser,
        planName: state.HeaderReducer.planName,
        remainingCredits: state.HeaderReducer.remainingCredits,
        refreshData: state.HeaderReducer.refreshData,
        subAccounts: state.subAccount.data,
        mainAccount: state.subAccount.main,
    };
};


const mapDispatchToProps = (dispatch) => {
    const { actions } = require("@store/redux/SubAccountRedux");
    return {
      handleLogoutSubmit: () => dispatch({ type: HANDLE_LOGOUT_SUBMIT }),
      switchAccount: (data) => {
        actions.switchBrandAccount(dispatch, data);
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
