import React, { Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "@components/global/Button";
import { FiX, FiCheck } from "react-icons/fi";
import { connect } from "react-redux";
import * as settingSubscriptionActions from "@store/actions/SettingSubscriptionActions";
import Loader from "@components/global/Loader";
import BillingCycle from "../BillingCycle";
import CancelSubscription from "../CancelSubscription";
import {
  HANDLE_BILLING_TYPE,
  HANDLE_DOWN_GRADE_MODAL_HIDE,
} from "@store/constants/action-types";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { FaSpinner, FaStar } from "react-icons/fa";
import Subscription from "./Subscriptions";
import { AiOutlineCheck } from "react-icons/ai";
import { BsDashLg } from "react-icons/bs";
import CustomLoader from "@components/global/CustomLoader";

const capitalize = (str) => {
  return (str || "").charAt(0).toUpperCase() + (str || "").slice(1);
};

class SettingSubscriptionPackages extends Component {
  constructor(props) {
    super(props);
    this.companyChange = this.companyChange.bind(this);
    this.state = {
      upgradeModal: false,
      planId: "",
      planPrice: "",
      planInterval: "",
      plan: {},
      couponCode: "",
      couponLoader: false,
      showCreditCard: false,
      downGradeModal: true,
      showPlans: false,
    };
  }

  companyChange(type) {
    this.props.billingTypeHandle(type);
  }

  showUpgradeModal = async (plan) => {
    this.setState({ plan: plan, upgradeModal: true });
  };

  showdownGradeModal = (planId, planPrice, planInterval) => {
    this.setState({
      downGradeModal: true,
      planId: planId,
      planPrice: planPrice,
      planInterval: planInterval,
    });
  };

  handleClose = () => {
    this.setState({
      upgradeModal: false,
      downGradeModal: false,
    });
  };

  handleDownGradePlan = (planId) => {
    const { defaultPaymentMethod } = this.props;
    if (
      defaultPaymentMethod &&
      typeof defaultPaymentMethod === "object" &&
      Object.keys(defaultPaymentMethod).length > 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to downgrade the Subscription?",
        icon: "question",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#7c3292",
        cancelButtonColor: "#f4f4f5",
        customClass: {
          actions: "flex-row-reverse",
          closeButton: "hover:text-[#7c3292]",
          confirmButton: "hover:!shadow-none focus:!shadow-none min-w-[100px]",
          cancelButton:
            "hover:!shadow-none focus:!shadow-none min-w-[100px] !text-[#202020]",
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.value) {
          this.props.downgradePlan(planId);
        }
      });
    } else {
      this.setState({ showCreditCard: true });
    }
  };

  handleUpGradePlan = () => {
    this.props.upgradePlan(this.state.plan.id);
    this.setState({
      upgradeModal: false,
    });
  };

  handleCancelSubscription = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to cancel the Subscription?",
      icon: "question",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No!",
      confirmButtonColor: "#7c3292",
      cancelButtonColor: "#f4f4f5",
      customClass: {
        actions: "flex-row-reverse",
        closeButton: "hover:text-[#7c3292]",
        confirmButton: "hover:!shadow-none focus:!shadow-none min-w-[100px]",
        cancelButton:
          "hover:!shadow-none focus:!shadow-none min-w-[100px] !text-[#202020]",
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        this.props.cancelSubscription();
      }
    });
  };

  couponApply = async () => {
    this.setState({ couponLoader: true });
    this.setState({ couponCode: "", couponLoader: false });
  };

  handleSubscriptionPlan = async () => {
    await this.props.subscribe({ id: this.state.plan?.id });

    this.props.fetchBrandSubscriptionPlans();
    this.props.refreshReports();
  };

  render() {
    const { plan, showPlans } = this.state;
    const { loader, subscription, currentLoggedUser, isLoading, isSubscribed } =
      this.props;

    if (isLoading) {
      return (
        <div className="flex justify-center items-center relative mt-36">
          <CustomLoader size="67" />
        </div>
      );
    }

    return (
      <div>
        {currentLoggedUser.is_shopify || showPlans || !isSubscribed ? (
          <>
            {(this.props.yearlyPlans || []).length > 0 && (
              <div className="flex flex-wrap mb-8 justify-center">
                <label className="my-6 flex items-center bg-bgBodyDark py-1 px-1 rounded-full">
                  <p
                    onClick={()=>this.companyChange(true)}
                    className={
                      "font-medium " +
                      (this.props.billingType === true
                        ? "bg-black rounded-full p-4 text-gray-300"
                        : "text-gray-300 p-4") +
                      " flex cursor-pointer items-center gap-x-3 ml-1"
                    }
                  >
                    Yearly 
                  </p>
                  <span className="bg-[#0c2112] ml-2 py-1 px-2 rounded-full text-[#4ccc8f] font-semibold">Save 20%</span>
                  <p
                    onClick={()=>this.companyChange(false)}
                    className={
                      "font-medium " +
                      (this.props.billingType === false
                        ? "bg-black rounded-full p-4 text-gray-300"
                        : "text-gray-300 p-4") +
                      " flex cursor-pointer items-center gap-x-3 ml-10 mr-1"
                    }
                  >
                    Monthly
                  </p>
                </label>
              </div>
            )}
            <div className="grid grid-cols-12 gap-5">
              {this.props.billingType === false &&
                this.props.monthlyPlans &&
                this.props.monthlyPlans.length > 0 &&
                this.props.monthlyPlans
                  .filter((plan) => plan.interval !== "one_time")
                  .map((plan, index) => (
                    <div
                      key={index}
                      className={`${index === 1 ? 'border-primary' : 'border-borderDark'} h-max my-auto border-[1px] lg:col-span-4 sm:col-span-6 col-span-12 mb-12 pb-10 rounded-3xl`}
                    >
                      {
                        index === 1 && <div className="bg-primary rounded-t-3xl h-14 gap-1 flex justify-center items-center">
                            <FaStar color="#e6a12d" size={17}  /> <p className="text-white font-semibold">Most Popular</p>
                        </div>
                      }
                      <div className="border-b  border-borderDark pb-4 px-5 pt-5">
                        <h6 className="text-[25px] font-firaSans font-semibold text-white">{plan.name}</h6>
                        <p className="font-medium text-lg font-firaSans text-[#7a7a7a]">
                          {index === 0
                            ? "Find, analyze and export Influencers."
                            : index === 1
                            ? "Manage campaigns end-to-end."
                            : "Streamline everything."}
                        </p>
                      </div>
                      <p className="font-semibold pt-5 text-white text-5xl px-5">
                        ${plan.price}{" "}
                        <sup className="font-normal text-[#7a7a7a] text-lg">per month</sup>
                      </p>
                      <div className="mt-4 px-5">
                        {index === 0 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Workspaces
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Team members
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : index === 1 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        )}
                        <div className="p-3">
                          {subscription?.stripe_plan === plan.stripe_id &&
                          (subscription?.stripe_status === "active" ||
                            subscription?.stripe_status === "past_due" ||
                            subscription?.stripe_status === "trialing") ? (
                            <React.Fragment>
                              <Button
                                disabled
                                className="w-full mt-4 px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg-[#e9eaec] text-[#9e9e9e] hover:opacity-80"
                                text="Selected Plan"
                              />
                            </React.Fragment>
                          ) : (
                            <div>
                              <Button
                                onClick={() => this.showUpgradeModal(plan)}
                                disabled={loader?.[plan.id]}
                                className="w-full mt-4 px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg-primary text-white hover:opacity-80"
                                text={
                                  loader?.[plan.id] ? (
                                    <FaSpinner className="animate-[spin_2s_linear_infinite] text-white" />
                                  ) : (
                                    "Subscribe"
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
            <div className="grid grid-cols-12 gap-5">
              {this.props.billingType === true &&
                this.props.yearlyPlans &&
                this.props.yearlyPlans.length > 0 &&
                this.props.yearlyPlans.map((plan, index) => (
                  <div
                      key={index}
                      className={`${index === 1 ? 'border-primary' : 'border-borderDark'} h-max my-auto border-[1px] lg:col-span-4 sm:col-span-6 col-span-12 mb-12 pb-10 rounded-3xl`}
                    >
                      {
                        index === 1 && <div className="bg-primary rounded-t-3xl h-14 gap-1 flex justify-center items-center">
                            <FaStar color="#e6a12d" size={17}  /> <p className="text-white font-semibold">Most Popular</p>
                        </div>
                      }
                      <div className="border-b  border-borderDark pb-4 px-5 pt-5">
                        <h6 className="text-[25px] font-firaSans font-semibold text-white">{plan.name}</h6>
                        <p className="font-medium text-lg font-firaSans text-[#7a7a7a]">
                          {index === 0
                            ? "Find, analyze and export Influencers."
                            : index === 1
                            ? "Manage campaigns end-to-end."
                            : "Streamline everything."}
                        </p>
                      </div>
                      <p className="font-semibold pt-5 text-white text-5xl px-5">
                        ${plan.price}{" "}
                        <sup className="font-normal text-[#7a7a7a] text-lg">per year</sup>
                      </p>
                      <div className="mt-4 px-5">
                        {index === 0 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Workspaces
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Team members
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : index === 1 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        )}
                        <div className="p-3">
                          {subscription?.stripe_plan === plan.stripe_id &&
                          (subscription?.stripe_status === "active" ||
                            subscription?.stripe_status === "past_due" ||
                            subscription?.stripe_status === "trialing") ? (
                            <React.Fragment>
                              <Button
                                disabled
                                className="w-full mt-4 px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg-[#e9eaec] text-[#9e9e9e] hover:opacity-80"
                                text="Selected Plan"
                              />
                            </React.Fragment>
                          ) : (
                            <div>
                              <Button
                                onClick={() => this.showUpgradeModal(plan)}
                                disabled={loader?.[plan.id]}
                                className="w-full mt-4 px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg-primary text-white hover:opacity-80"
                                text={
                                  loader?.[plan.id] ? (
                                    <FaSpinner className="animate-[spin_2s_linear_infinite] text-white" />
                                  ) : (
                                    "Subscribe"
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                ))}
            </div>
          </>
        ) : (
          <Subscription
            setShowPlans={(value) => this.setState({ showPlans: value })}
          />
        )}
        {showPlans && !currentLoggedUser.is_shopify && (
          <>
            <div className="mb-12">
              <BillingCycle />
            </div>
            <div className="mb-12">
              <CancelSubscription />
            </div>
          </>
        )}

        <Transition appear show={this.state.upgradeModal} as={Fragment}>
          <Dialog onClose={this.handleClose} className="relative z-[9999]">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto sm:min-w-[36rem] min-w-full rounded-[8px] bg-bgBodyLight">
                  <Dialog.Title className="text-white text-center grow flex justify-between border-b border-borderDark p-3">
                    <h2 className="text-[24px]">Subscribe to {plan.name}</h2>
                    <div
                      className="bg-primary px-[12px] rounded-b-[8px] -mt-[18px] -mr-[13px] h-[46px] flex items-center cursor-pointer"
                      onClick={this.handleClose}
                    >
                      <FiX size={24} className="text-white stroke-white" />
                    </div>
                  </Dialog.Title>
                  <div className="p-3">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[2px]">
                            Plan
                          </th>
                          <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[2px]">
                            Price
                          </th>
                          <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[2px]">
                            Billing
                          </th>
                          <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[2px]">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="font-normal text-white text-[14px] p-[0.75rem]">
                            {plan.name}
                          </td>
                          <td className="font-normal text-white text-[14px] p-[0.75rem]">
                            ${plan.price}
                          </td>
                          <td className="font-normal text-white text-[14px] p-[0.75rem]">
                            {plan.interval === "one_time"
                              ? "One Time"
                              : `Billed ${capitalize(plan.interval)}ly`}
                          </td>
                          <td className="font-normal text-white text-[14px] p-[0.75rem]">
                            ${plan.price}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div>
                      <div className="flex items-center justify-end py-4 border-b border-borderDark">
                        <p className="text-[15px] text-white font-normal">Subtotal :</p>
                        <b className="px-12 text-white">${plan?.price}</b>
                      </div>
                      <div className="flex items-center justify-end py-4">
                        <p className="text-[15px] text-white font-normal">Total :</p>
                        <b className="px-12 text-white">${plan?.price}</b>
                      </div>
                    </div>
                    <div className="flex justify-end pt-2 border-t border-borderDark">
                      <Button
                        className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg--lightGray dark hover:opacity-80 mt-2"
                        onClick={this.handleClose}
                        text="Cancel"
                      />
                      <Button
                        className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80  disabled:opacity-70 mt-2 ml-4"
                        onClick={this.handleSubscriptionPlan}
                        text={
                          loader?.[plan.id] ? (
                            <FaSpinner className="animate-[spin_2s_linear_infinite] text-white" />
                          ) : (
                            "Subscribe"
                          )
                        }
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={this.props.isDownGraded} as={Fragment}>
          <Dialog
            onClose={this.props.handleCloseDownGradeModal}
            className="relative z-[9999]"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto sm:min-w-[36rem] min-w-full rounded-[8px] bg-white">
                  <Dialog.Title className="text-white text-center grow flex justify-between border-b border-[#dee2e6] p-3">
                    <h2 className="text-[24px]">Success</h2>
                    <div
                      className="bg-[#000] px-[12px] rounded-b-[8px] -mt-[18px] -mr-[13px] h-[46px] flex items-center cursor-pointer"
                      onClick={this.props.handleCloseDownGradeModal}
                    >
                      <FiX size={24} className="text-white stroke-white" />
                    </div>
                  </Dialog.Title>
                  <div className="p-3">
                    <p className="text-center">
                      {" "}
                      Your subscription was successfully downgraded. it is
                      effective immediately!
                    </p>
                    <div className="flex justify-end pt-2 border-t border-[#dee2e6] mt-4">
                      <Button
                        className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg--lightGray dark hover:opacity-80 mt-2"
                        onClick={this.props.handleCloseDownGradeModal}
                        text="Ok"
                      />
                      <Button
                        className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg--purple text-white hover:opacity-80  disabled:opacity-70 mt-2 ml-4"
                        onClick={this.handleUpGradePlan}
                        text="Yes"
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.SettingSubscriptionReducer.isLoading,
    monthlyPlans: state.SettingSubscriptionReducer.monthlyPlans,
    yearlyPlans: state.SettingSubscriptionReducer.yearlyPlans,
    interval: state.SettingSubscriptionReducer.interval,
    billingType: state.SettingSubscriptionReducer.billingType,
    isDownGraded: state.SettingSubscriptionReducer.isDownGraded,
    currentLoggedUser: state.HeaderReducer.currentLoggedUser,
    plan: state.RegisterReducer.plan,
    defaultPaymentMethod: state.SettingPaymentReducer.defaultPaymentMethod,
    subscription: state.SettingSubscriptionReducer.subscription,
    isSubscribed: state.SettingSubscriptionReducer.subscribed,
    loader: state.billing.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("@store/redux/BillingRedux");
  const { refreshReports } = require("@store/actions/HeaderActions");
  return {
    upgradePlan: (id) => dispatch(settingSubscriptionActions.upgradePlan(id)),
    downgradePlan: (id) =>
      dispatch(settingSubscriptionActions.downgradePlan(id)),
    handleCloseDownGradeModal: (event) =>
      dispatch({ type: HANDLE_DOWN_GRADE_MODAL_HIDE }),
    cancelSubscription: () =>
      dispatch(settingSubscriptionActions.cancelSubscription()),
    subscribe: (data) => actions.subscribe(dispatch, data),
    fetchBrandSubscriptionPlans: () =>
      dispatch(settingSubscriptionActions.fetchBrandSubscriptionPlans()),
    refreshReports: () => dispatch(refreshReports()),
    billingTypeHandle: (event) =>
      dispatch({ type: HANDLE_BILLING_TYPE, payload: event }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingSubscriptionPackages);
