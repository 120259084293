import { Component } from "react";
import Location from "@components/BrandInfluencerDiscover/Filters/Location";
import Language from "@components/BrandInfluencerDiscover/Filters/Language";
import Brands from "@components/BrandInfluencerDiscover/Filters/Brands";
import Partnerships from "@components/BrandInfluencerDiscover/Filters/Partnerships";
import Interests from "@components/BrandInfluencerDiscover/Filters/Interests";
import Gender from "@components/BrandInfluencerDiscover/Filters/Gender";
import Ethnicity from "@components/BrandInfluencerDiscover/Filters/Ethnicity";
import Age from "@components/BrandInfluencerDiscover/Filters/Age";
import Lookalikes from "@components/BrandInfluencerDiscover/Filters/Lookalikes";
import Bio from "@components/BrandInfluencerDiscover/Filters/Bio";
import Followers from "@components/BrandInfluencerDiscover/Filters/Followers";
import ReelPlay from "@components/BrandInfluencerDiscover/Filters/ReelPlay";
import Engagements from "@components/BrandInfluencerDiscover/Filters/Engagements";
import LastPost from "@components/BrandInfluencerDiscover/Filters/LastPost";
import AccountType from "@components/BrandInfluencerDiscover/Filters/AccountType";
import Growing from "@components/BrandInfluencerDiscover/Filters/Growing";
import Tags from "@components/BrandInfluencerDiscover/Filters/Tags";
import { connect } from "react-redux";
import FindInfluencers from "@components/BrandInfluencerDiscover/Filters/FindInfluencers";
import WordCloud from "react-d3-cloud";
import Email from "@components/BrandInfluencerDiscover/Filters/Email";
import Mentions from "@components/BrandInfluencerDiscover/Filters/Mentions";
import Hashtags from "@components/BrandInfluencerDiscover/Filters/Hashtags";
import Keywords from "@components/BrandInfluencerDiscover/Filters/Keywords";

class InstagramFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
     toggleFilter : false
    };
  }
  render() {
    const { customContainer, relevantTags } = this.props;
    return (
      <div className={customContainer}>
        <div className="bg-bgBodyLight">
          <div className="flex flex-wrap px-6 pt-6">
            <div className="w-full">
              <div className="flex mb-5">
                <div className="flex flex-wrap items-center rounded-[5px] gap-3">
                  <Location className="gradient-filter-button" />
                  <Language className="gradient-filter-button"/>
                  <Followers className="gradient-filter-button"/>
                  <Engagements className="gradient-filter-button"/>
                  <Lookalikes className="gradient-filter-button"/>
                  <ReelPlay className="gradient-filter-button"/>
                  <Age className="gradient-filter-button"/>
                  <button onClick={()=>this.setState({toggleFilter:!this.state.toggleFilter})} className="px-3 whitespace-nowrap border-primary border rounded-[8px] h-[40px] text-[14px] xs:w-auto w-full justify-center inline-flex items-center bg-transparent text-white hover:opacity-80">{this.state.toggleFilter ? 'Less filters' : 'More filters'}</button>
                  <FindInfluencers />
                </div>
              </div>
              <div className={`${this.state.toggleFilter ? 'flex' : 'hidden'} mb-5`}>
                <div className="flex flex-wrap items-center rounded-[5px] gap-3">
                  <Mentions className="gradient-filter-button" />
                  <Hashtags className="gradient-filter-button"/>
                  <Keywords className="gradient-filter-button" />
                  <Bio className="gradient-filter-button"/>
                  <Brands className="gradient-filter-button"/>
                  <Partnerships className="gradient-filter-button"/>
                  <Ethnicity className="gradient-filter-button"/>
                  <LastPost className="gradient-filter-button"/>
                </div>
              </div>
              <div className={`${this.state.toggleFilter ? 'flex' : 'hidden'} mb-5`}>
                <div className="flex flex-wrap items-center rounded-[5px] gap-3">
                  <AccountType className="gradient-filter-button"/>
                  <Gender className="gradient-filter-button"/>
                  <Growing className="gradient-filter-button" />
                  <Interests className="gradient-filter-button"/>
                  <Email  className="gradient-filter-button"/>
                </div>
              </div>
            </div>
            {relevantTags && relevantTags.length > 0 && (
              <div className="sm:w-3/12 hidden">
                <p className="font-bold">Topic Tensor</p>
                <div className="bg-[rgb(249_249_249)] border border-[#efefef]">
                  <WordCloud
                    data={relevantTags}
                    width={200}
                    height={100}
                    font="'Poppins', sans-serif"
                    fontStyle="normal"
                    fontWeight="bold"
                    fontSize={(word) => word.value}
                    spiral="archimedean"
                    rotate={() => 0}
                    padding={1}
                    random={Math.random}
                    fill={() => "#7c3292"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center flex-wrap mb-4">
          <Tags />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ influencerSearch }) => {
  return {
    relevantTags: influencerSearch.searchRelevantTags,
  };
};

export default connect(mapStateToProps)(InstagramFilters);
