import { Component } from "react";
import { connect } from "react-redux";
import Tooltip from "@components/global/Tooltip";
import InfluencerProfileModal from "@components/BrandInfluencerDiscover/Profile/InfluencerProfileModal";
import avatar from "@assets/avatar.webp";
import SocialListIcons from "../../../constants/SocialListIcons";
import "./styles.css";
import Emitter from "../../../constants/Emitter";
import { RiExternalLinkLine } from "react-icons/ri";
import { FiEye, FiUsers } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";

const FormatedNumber = ({ num }) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

class InstagramItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileModal: false,
      showmodal: false,
      influencerUserId: "",
      errorCount: 0,
    };
  }

  componentDidMount = () => {
    const data = {
      clear: true,
    }

    this.props.clearSelectedList(data);
  }

  handleInfluencerProfileModal = async (id) => {
    if (id) {
      this.setState({
        profileModal: true,
      });

      const json = await this.props.viewInfluencerProfile(id);

      if (json?.data?.error) {
        this.setState({
          profileModal: false,
        });
      }
    }
  };

  handleClose = () => {
    this.setState({
      profileModal: false,
    });
  };

  _handleInfluencerSelection = (e, influencer) => {
    const { addInfluencer, newCampaignWithSelected, addSelectedList } = this.props;
    addInfluencer(e.target.checked, influencer, newCampaignWithSelected);
    addSelectedList({ checked: e.target.checked, card: influencer, clear: false });
  };
  showInfluencerList = (item) => {
    if (this.props.refreshData.is_admin) {
      this.setState({ showmodal: true });
      this.setState({ influencerUserId: item });
      let query = {
        platform: this.props.platform,
      };
      this.props.fetchBrandLists(query);
    } else {
      Emitter.emit("PERMISSION_POPUP");
    }
  };
  closeInfluenceModalList = () => {
    this.setState({ showmodal: false });
  };
  searchBrand = (data) => {
    this.props.fetech.searchBrand(data);
  };

  handleImageError = (event) => {
    const { currentTarget } = event;
    this.setState({ errorCount: (prevErrorCount) => prevErrorCount + 1 });

    if (this.state.errorCount < 3) {
      const fallbackImage =
        this.props.influencer.user_profile?.picture ||
        `${process.env.REACT_APP_BASE_URL}/images/male_avatar.webp`;
      currentTarget.src = fallbackImage;
    } else {
      currentTarget.src = avatar;
    }
  };

  handleListModal = (influencer)=>{
    const { addInfluencer, newCampaignWithSelected, addSelectedList,showInfluencerList,selected_influencers } = this.props;
    const existInfluencer = selected_influencers.some((selectedInfluencer) => selectedInfluencer.user_profile.user_id === influencer.user_profile.user_id);
    if(!existInfluencer){
      addInfluencer(true, influencer, newCampaignWithSelected);
      addSelectedList({ checked: true, card: influencer, clear: false });
      setTimeout(() => {
        showInfluencerList();
      }, 100);
    }else{
      setTimeout(() => {
        showInfluencerList();
      }, 100);
    }
  }

  render() {
    const {
      isProfileLoading,
      influencer,
      platform,
      selected_influencers,
      newCampaignWithSelected,
    } = this.props;

    return (
      <>
        <div
          className={
            "relative outline outline-1 hover:bg-[#353A4E] highlight_parent gap-5 flex-wrap flex items-center py-5 lg:px-10 px-5 bg-bgBodyLight rounded-[8px] overflow-hidden mb-6 sm:!mb-0 group" +
            (selected_influencers.find(
              (selectedInfluencer) =>
                selectedInfluencer.user_profile.user_id ===
                influencer.user_profile.user_id
            )
              ? "shadow-[0_0_10px_0_#603ed3] outline-[#603ed3] selected-user "
              : "shadow-[0px_4px_5px_#96969640] outline-transparent")
          }
        >
          <div className="flex shrink-0 w-full max-w-xl items-center gap-x-5">
            <label
              htmlFor={`influencer-` + influencer.user_profile.user_id}
              className="cursor-pointer flex items-center text-[15px] font-normal"
            >
              <input
                id={`influencer-` + influencer.user_profile.user_id}
                type="checkbox"
                disabled={selected_influencers.some(
                  (selectedInfluencer) =>
                    selectedInfluencer.user_profile.user_id ===
                    influencer.user_profile.user_id &&
                    selectedInfluencer.isActive
                )}
                checked={
                  selected_influencers && selected_influencers.length > 0
                    ? selected_influencers.some((selectedInfluencer) =>
                      selectedInfluencer.user_profile.user_id !==
                        influencer.user_profile.user_id
                        ? false
                        : true
                    )
                    : false
                }
                onChange={(e) =>
                  this._handleInfluencerSelection(e, influencer)
                }
                className="hidden peer"
              />
              <span className="peer-checked:bg-[#fff] bg-transparent h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.2px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-bgBodyLight inline-block border-2 border-[#fff] rounded-sm"></span>
            </label>
            <div
              className="relative block"
            >
              <img
                src={
                  influencer.user_profile
                    ? influencer.user_profile.picture
                    : avatar
                }
                alt={
                  influencer.user_profile.fullname
                    ? influencer.user_profile.fullname
                    : ""
                }
                className="min-h-[100px] max-h-[100px] rounded-full w-full"
                onError={this.handleImageError}
              />

            </div>
            <div className="space-y-2">
              <b className="block text-white">
                <Tooltip
                  trigger={
                    <p className="card-name font-semibold text-xl cursor-default">
                      {" "}
                      {influencer.user_profile.fullname
                        ? influencer.user_profile.fullname
                        : ""}
                    </p>
                  }
                  tooltipText={
                    influencer.user_profile.fullname
                      ? influencer.user_profile.fullname
                      : ""
                  }
                  placement="top-left"
                />
              </b>
              <div className="flex items-center gap-x-6">
                <p className="text-white">{influencer.user_profile.username}</p>
                <a target="_blank" href={influencer.user_profile?.url} className="text-white flex items-center capitalize opacity-60 gap-x-1">View {platform} Account <RiExternalLinkLine size={16}  /></a>
              </div>
            </div>
          </div>

          <div className="flex grow justify-between gap-x-10">
            <div className="flex items-center gap-x-5">
              <FiUsers size={20} color="white" />
              <div className="space-y-1">
                <p className="text-white font-normal opacity-60">
                  Followers
                </p>
                <b className="text-white">
                  <FormatedNumber num={influencer.user_profile.followers} />
                </b>
              </div>
            </div>
            <div className="flex items-center gap-x-5">
              <FaRegHeart size={20} color="white" />
              <div className="space-y-1">
                <p className="text-white font-normal opacity-60">
                  Engagement Rate
                </p>
                <b className="text-white">
                  {influencer.user_profile.engagement_rate
                    ? (influencer.user_profile.engagement_rate * 100).toFixed(2)
                    : 0}
                  % 
                </b>
              </div>
            </div>
            {
              platform === "instagram" && 
              <div className="flex items-center gap-x-5">
                <FaRegHeart size={20} color="white" />
                <div className="space-y-1">
                  <p className="text-white font-normal opacity-60">
                    Engagement Count
                  </p>
                  <b className="text-white">
                    {influencer.user_profile.engagements ? (
                      <FormatedNumber num={influencer.user_profile.engagements} />
                    ) : (
                      0
                    )}{" "}
                  </b>
                </div>
              </div>
            }
            {platform !== "instagram" && (
              <div className="flex items-center gap-x-5">
                <FiEye size={20} color="white" />
                <div className="space-y-1">
                  <p className="text-white font-normal opacity-60">
                    Views Count
                  </p>
                  <b className="text-white">
                    {influencer.user_profile &&
                      influencer.user_profile.avg_views ? (
                      <FormatedNumber num={influencer.user_profile.avg_views} />
                    ) : (
                      0
                    )}
                  </b>
                </div>
              </div>
            )}
            <button onClick={()=>this.handleListModal(influencer)} className="text-white highlight_bg hover:drop-shadow-[0_5px_20px_rgba(255,255,255,1)] bg-bgBodyDark py-2 px-10">Add to list</button>
          </div>
        </div>
        <InfluencerProfileModal
          newCampaignWithSelected={newCampaignWithSelected}
          isProfileLoading={isProfileLoading}
          platform={platform}
          open={this.state.profileModal}
          onClose={() => this.handleClose()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isProfileLoading: state.influencerSearch.isProfileLoading,
    selected_influencers: state.campaign.selected_influencers,
    refreshData: state.HeaderReducer.refreshData,
    selectedCards: state.brandList.selectedCards,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  const { types } = require("@store/redux/CampaignRedux");
  const { actions: brandlistactions } = require("@store/redux/BrandListRedux");
  return {
    ...ownProps,
    ...stateProps,
    viewInfluencerProfile: (data) => {
      return actions.viewInfluencerProfile(dispatch, data);
    },
    addInfluencer: (status, influencer, flag) => {
      dispatch({
        type: types.HANDLE_ADD_INFLUENCER,
        data: { status: status, influencer: influencer, flag: flag },
      });
    },

    addSelectedList: (data) => {
      brandlistactions.handleSelectedListCard(dispatch, data);
    },
    clearSelectedList: (data) => {
      brandlistactions.handleSelectedListCard(dispatch, data);
    },

    fetchBrandLists: (data) => {
      brandlistactions.fetchBrandLists(dispatch, data);
    },

    searchBrand: (data) => {
      brandlistactions.searchBrand(dispatch, data);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(InstagramItems);
