import { Component } from "react";
import { Link } from "react-router-dom";
import logo from "@assets/btrendy-new-logo.webp";
import { NavLink } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import * as headerActions from "@store/actions/HeaderActions";
import { connect } from "react-redux";
import { Transition, Menu } from "@headlessui/react";
import Anchor from "@components/global/Anchor";
import { Fragment } from "react";
import { FiTool } from "react-icons/fi";
class LandingPageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }
  componentDidMount() {
    const path = window.location.pathname;
    this.props.refreshReports();
    if (path) {
      const route = path.split("/");
      if (route) {
        if (route[1]) {
          if (route[1] === "register") {
            this.setState({ show: false });
          }
        }
      }
    }

    this.props.currentLoggedInUser();
    document.querySelector("body").addEventListener("click", setScroll);
    function setScroll() {
      let state = document.querySelector(
        "#preserve_scroll button[data-headlessui-state=open]"
      );
      if (state) {
        document.querySelector("html").style.overflow = "hidden";
      } else {
        document.querySelector("html").style.overflow = "";
      }
    }
  }
  render() {
    const { show } = this.state;
    return (
      <>
        <div className="sticky top-0 left-0 right-0 z-[11] min-h-[75px] bg-bgBody border-b border-[#f7f7f7] flex items-center">
          <div className="containers px-[1rem]">
            <div className="flex items-center justify-between py-6 md:space-x-10">
              <div className="flex justify-start shrink-0">
                <Link to={process.env.REACT_APP_LANDING_URL}>
                  <img className="w-[90px]" src={logo} alt="logo" />
                </Link>
              </div>
              {/** Free tool */}
              <div className="relative group hidden after:content-[''] after:absolute after:top-0 after:left-0 after:right-0 after:h-[38px] cursor-pointer">
                <button
                  type="button"
                  className="black inline-flex items-center rounded-md text-[14px] font-medium  focus:outline-none"
                  aria-expanded="false"
                >
                  <FiTool className="overflow-hidden text-white mr-1 shrink-0 w-[16px] h-[16px]" />
                  <span className="font-normal text-white">Free tools</span>
                </button>

                <div className="absolute group-hover:block hidden z-10  mt-4 w-screen max-w-[600px] transform px-2 sm:!px-0 lg:!ml-0 -translate-x-1 ">
                  <div className="overflow-hidden rounded-[8px] shadow-lg bg-bgBodyLight">
                    <div className="flex">
                      <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                        <NavLink
                          to="/instagram-niche"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Niche Instagram Influencers
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Search for influencers by topics they talk about
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/instagram-location"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Instagram Location search
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find instagram influencers by location
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/instagram-engagement-calculator"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Instagram ER calculator
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Calculate the engagement rate for any influencer
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/tiktok-engagement-calculator"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              TikTok ER calculator
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Calculate the engagement rate for any influencer
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/instagram-email-finder"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Instagram Email Finder
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find emails for Instagram influencer
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/youtube-email-finder"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Youtube Email Finder
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find emails for Youtube influencer
                            </p>
                          </div>
                        </NavLink>
                      </div>
                      <div className="relative grid gap-6 bg-bgBodyLight p-6 sm:gap-8">
                        <NavLink
                          to="/tiktok-niche"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Niche Tiktok Influencers
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Search for influencers by topics they talk about
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/tiktok-location"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              TikTok Location search
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find tiktok influencers by location
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/youtube-niche"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Niche Youtube Influencers
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Search for influencers by topics they talk about
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/youtube-location"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Youtube Location search
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find Youtube influencers by location
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/tiktok-email-finder"
                          className={({ isActive }) => [
                            "-m-3 text-[14px] rounded-[8px] p-3 font-medium text-white block ",
                            isActive ? " bg-bgBodyDark" : "",
                          ]}
                        >
                          <div>
                            <p className="text-[14px] font-medium text-white">
                              Tiktok Email Finder
                            </p>
                            <p className="mt-1 text-sm text-white/80">
                              Find emails for Tiktok influencer
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/** end free tool */}

              {show && (
                <>
                  <div className="hidden items-center justify-end xl:flex md:flex-1 lg:w-0">
                    {localStorage.getItem("isLogin") ? (
                      <a href={"/dashboard"} className="text-white">Dashboard</a>
                    ) : (
                      <>
                        <NavLink
                          to="/brand/login"
                          className={({ isActive }) => 
                          `-m-3 text-[14px] rounded-[8px] p-3 font-medium block ${isActive ? "text-primary" : "text-white"}`
                        }
                        >
                          Login
                        </NavLink>
                        <NavLink
                          to="/brand/register"
                          className="ml-6 inline-flex items-center justify-center whitespace-nowrap rounded-md border-[1px] text-[12px] bg-primary border-[#603ed3] px-4 py-[0.5rem] font-medium text-white hover:opacity-80"
                        >
                          Get Started
                        </NavLink>
                      </>
                    )}
                  </div>
                  <div className="-my-2 -mr-2 xl:hidden flex flex-1 justify-end pr-3">
                    <Menu as="div" className="bg-transparent" id="preserve_scroll">
                      <Menu.Button className="bg-whte rounded-md p-2 hover:text-white inline-flex items-center justify-center text-white/80  hover:bg-gray-700">
                        <HiOutlineMenu size={30} className="text-white" />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute top-20 max-w-[92%] mx-auto z-2xlfull inset-x-0 py-[0.5rem] transition transform origin-top-right xxl:hidden">
                          <div className="rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="py-10 px-12 space-y-10">
                              {localStorage.getItem("isLogin") ? (
                                <Menu.Item>
                                  <Anchor
                                    className="inline-flex items-center black rounded-md hover:success black text-[14px] font-normal  focus:outline-none"
                                    href={
                                      "/" + localStorage.role + "/dashboard"
                                    }
                                    text="Dashboard"
                                  />
                                </Menu.Item>
                              ) : (
                                <>
                                  <div className="flex flex-wrap xxs:flex-nowrap gap-4">
                                    <Menu.Item>
                                      <NavLink
                                        to="/brand/login"
                                        className={({ isActive }) => [
                                          " items-center rounded-md hover:success bg-white text-[14px] font-normal block focus:outline-none",
                                          isActive
                                            ? " success"
                                            : " text-black",
                                        ]}
                                      >
                                        <p className="text-[14px] font-medium black">
                                          Login
                                        </p>
                                      </NavLink>
                                    </Menu.Item>
                                  </div>
                                  <div className="flex flex-wrap xxs:flex-nowrap gap-4">
                                    <Menu.Item>
                                      <NavLink
                                        to="/brand/register"
                                        className="flex items-center justify-center whitespace-nowrap flex-1 rounded-md border-[1px] text-[12px] border--purple bg-primary px-4 py-[0.5rem] font-medium text-white hover:opacity-80"
                                      >
                                        Get Started
                                      </NavLink>
                                    </Menu.Item>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    currentLoggedInUser: () => dispatch(headerActions.currentLoggedInUser()),
    refreshReports: () => dispatch(headerActions.refreshReports()),
  };
};

export default connect(null, mapDispatchToProps)(LandingPageHeader);
