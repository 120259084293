import { useEffect, useRef, useState } from "react";
import SocialListIcons from "@constants/SocialListIcons";
import { mergeRefs } from "@constants/Board";
import { useSelector } from "react-redux";
import avatar from "@assets/avatar.webp";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const getCardStyle = (isDragging, defaultStyle) => {
  if (!isDragging)
    return {
      ...defaultStyle,
      cursor: "pointer",
    };
  return {
    ...defaultStyle,
    transform: defaultStyle.transform + " rotate(5deg)",
    cursor: "grabbing",
  };
};

const Card = ({
  card,
  list,
  provided,
  isDragging,
  handleOpenInfluencerModal,
}) => {
  const { actions } = require("@store/redux/BrandListRedux");
  const currentList = useSelector((state) => state.brandList.current_list);
  const selectedCard = useSelector((state) => state.brandList.selectedCards);
  const { innerRef, draggableProps, dragHandleProps } = provided;
  const cardElem = useRef(null);
  const [errorCount, setErrorCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      clear: true
    }
    actions.handleSelectedListCard(dispatch, data);
  }, [])

  const handleImageError = (event) => {
    const { currentTarget } = event;
    setErrorCount(errorCount + 1);

    if (errorCount < 3) {
      const fallbackImage =
        card.picture ||
        `${process.env.REACT_APP_BASE_URL}/images/male_avatar.webp`;
      currentTarget.src = fallbackImage;
    } else {
      currentTarget.src = avatar;
    }
  };

  const handleSelectedListCard = (e, card) => {
    const data = {
      checked: e.target.checked,
      clear: false,
      card
    }
    actions.handleSelectedListCard(dispatch, data);
  }

  const selectedCardCheck = selectedCard?.some(item => item.id === card.id);

  return (
    <div
      ref={mergeRefs(cardElem, innerRef)}
      {...draggableProps}
      style={getCardStyle(isDragging, draggableProps.style)}
      {...dragHandleProps}
      key={card.id}
      className={`bg-transparent flex items-center border relative ${selectedCardCheck ? 'shadow-[0_0_5px_0_#603ed3] border-primary' : 'border-white'} overflow-x-hidden group rounded-xl p-2 my-2 cursor-pointer ${list.board_influencers.length > 5 && "overflow-y-auto"
        }`}
    >
      {
        currentList.listInfluencersCount > 12 &&
        <div className="absolute -right-20 group-hover:right-0 overflow-hidden group-hover:bg-primary flex justify-center items-center w-1/4 h-full transition-all ease-in-out duration-500">
          <label
            htmlFor={`card` + card.id}
            className="cursor-pointer ml-2 flex items-center text-[15px] font-normal"
          >
            <input
              id={`card-` + card.id}
              type="checkbox"
              name={card.id}
              onChange={(e) =>
                handleSelectedListCard(
                  e,
                  card
                )
              }
              className="mr-3 bg-white cursor-pointer checked:bg-primary h-[16px] w-[16px] inline-block !border-2 !border-primary rounded-sm"
            />
          </label>
        </div>
      }
      <div
        onClick={() => handleOpenInfluencerModal(card)}
        className="grow overflow-x-auto flex justify-evenly items-center"
      >
        <div className="w-[80px] truncate">
          <img
            src={card.picture}
            alt={card.fullname}
            width={50}
            height={50}
            className="rounded-full"
            onError={handleImageError}
          />
        </div>
        <span className="w-[40px] text-white">
          {SocialListIcons(currentList?.list_channel || "instagram", 20)}
        </span>
        <span className="flex-1 text-white truncate text-center">{card.fullname}</span>
      </div>
    </div>
  );
};

export default Card;
