import CustomLoader from "@components/global/CustomLoader";
import React, { Component } from "react";
import { FaSpinner } from "react-icons/fa";

function Message(time) {
  if (time.time >= 0 && time.time <= 3) {
    return (
      <h4 className="text-white text-[14px] font-normal tracking-[1px] mt-4">
        Crunching numbers and analyzing trends to provide you with the best influencer profiles.
      </h4>
    );
  } else if (time.time >= 4 && time.time <= 7) {
    return (
      <h4 className="text-white text-[14px] font-normal tracking-[1px] mt-4">
        Connecting the dots and gathering the most relevant data for you.
      </h4>
    );
  } else if (time.time >= 8) {
    return (
      <h4 className="text-white text-[14px] font-normal tracking-[1px] mt-4">
        Diving deep into the data ocean to bring you the most accurate insights.
      </h4>
    );
  } else {
    return null;
  }
}

class MiniProfileLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
    };
  }

  componentDidMount() {
    setInterval(() => this.setState({ time: this.state.time + 1 }), 1000);
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  render() {
    const { time } = this.state;
    return (
      <div className="w-full h-full z-[11] absolute flex inset-0 justify-center items-center">
        <div className="text-center relative">
          <CustomLoader />
          <Message time={time} />
        </div>
      </div>
    );
  }
}

export default MiniProfileLoader;
