import React, { Component } from "react";
import { connect } from "react-redux";
import IqInfluencerDiscoveryTab from "@components/BrandInfluencerDiscover/NavTabs";
import TiktokFilters from "./TiktokFilters";
import TiktokItems from "./TiktokItems";
import { Helmet } from "react-helmet";
import SubHeader from "@components/Header/SubHeader";

class BrandInfluencersDiscoverTiktok extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.timeout) clearTimeout(this.timeout);
    let payload = Object.assign({}, this.props.payload);
    const form = Object.assign({}, this.props.form);
    const actions = Object.assign([], this.props.actions);
    if (actions.length > 0) {
      payload = {
        ...payload,
        filter: {
          ...payload.filter,
          actions: actions,
        },
      };
    }
    let query = {
      platform: "tiktok",
      payload: payload,
      override_filter: form.override_filter,
    };

    this.timeout = setTimeout(() => {
      this.props.searchInfluencers(query);
      this.props.searchInfluencersCount(query);
    }, 500);
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Discover | Tiktok | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <SubHeader title={'Find Influencers'} />
        <div className="mb-12">
          <div className="pt-6">
            <IqInfluencerDiscoveryTab />
            <TiktokFilters customContainer="px-0" />
          </div>
          <div className="mt-12">
            <TiktokItems newCampaignWithSelected={true} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ influencerSearch }) => {
  return {
    platform: influencerSearch.platform,
    form: influencerSearch.form,
    payload: influencerSearch.payload,
    actions: influencerSearch.actions,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  return {
    ...ownProps,
    ...stateProps,
    searchInfluencers: (data) => {
      actions.searchInfluencers(dispatch, data);
    },
    searchInfluencersCount: (data) => {
      actions.searchInfluencersCount(dispatch, data);
    },
  };
};

export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(BrandInfluencersDiscoverTiktok);
