import { useState } from "react";
import { connect } from "react-redux";
import Items from "@components/BrandInfluencerDiscover/Items/Items";
import Sort from "@components/BrandInfluencerDiscover/Filters/Sort";
import SelectedItems from "@components/BrandInfluencerDiscover/Items/SelectedItems";
import Loader from "@components/global/Loader";
import Emitter from "../../../constants/Emitter";
import { Disclosure, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import InfluencerListModal from "@components/InfluencerListModal";
import Button from "@components/global/Button";
import Pagination from "@components/Pagination";

const YoutubeItems = (props) => {
  const [showModal, setShowModal] = useState(false);

  const onPageChanged = (pageData) => {
    // if (props.refreshData.is_admin) {
    const payload = Object.assign({}, props.payload);
    const form = Object.assign({}, props.form);
    payload["paging"]["skip"] =
      (pageData?.currentPage || 0) * (pageData?.pageLimit || 0) -
      pageData?.pageLimit;
    form["loadMore"] = true;
    props.searchFilters(payload, form);
    let query = {
      platform: props.platform,
      payload: payload,
      isCreditDeduct: true,
      override_filter: true,
    };
    props.searchInfluencers(query);
    // } else {
    //   Emitter.emit("PERMISSION_POPUP");
    // }
  };

  const removeAll = () => {
    const { removeAllInfluencers } = props;
    removeAllInfluencers();
  };

  const closeInfluenceModalList = () => {
    setShowModal(false);
  };

  const showInfluencerList = () => {
    if (props.refreshData.is_admin) {
      setShowModal(true);
      let query = {
        platform: props.platform,
      };
      props.fetchBrandLists(query);
    } else {
      Emitter.emit("PERMISSION_POPUP");
    }
  };

  const {
    isLoading,
    influencers,
    form,
    selected_influencers,
    newCampaignWithSelected,
    campaign_status,
    influencerTotal,
  } = props;
  return (
    <>
      <div className="w-full pr-3">
        <div className="pb-12 relative">
          {form.loadMore && influencers.length === 0 ? (
            <div className="py-12">
              <Loader
                className="h-full w-full flex justify-center items-center"
                size="67"
              />
            </div>
          ) : (
            <div>
              {influencers && influencers.length ? (
                <>
                  <Sort platform={props.platform} showInfluencerList={showInfluencerList} removeAll={removeAll} newCampaignWithSelected={newCampaignWithSelected} />
                  <div className="grid grid-cols-12 gap-5">
                    {influencers.map((influencer, index) => (
                      <div
                        className="mb-4 col-span-12"
                        key={index}
                      >
                        <Items
                          influencer={influencer}
                          newCampaignWithSelected={newCampaignWithSelected}
                          platform={"youtube"}
                          showInfluencerList ={showInfluencerList}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="text-center w-full py-[5rem] px-[1rem] justify-center text-[#bbb] text-[2.8rem] font-medium leading-[40px]">
                  We have nothing to show you here.
                </div>
              )}
            </div>
          )}
        </div>
        <div className="inline-flex items-center justify-center">
          {!isLoading && (influencerTotal || 0) > 10 && (
            <Pagination
              totalRecords={
                (influencerTotal || 0) <= 24
                  ? influencerTotal || 0
                  : Math.min(influencerTotal || 0, 10000) - 10
              }
              pageLimit={10}
              pageNeighbours={10}
              onPageChanged={onPageChanged}
            />
          )}

          {influencers &&
            influencers.length > 0 &&
            !isLoading &&
            form.loadMore && <Loader size="30" />}
        </div>
      </div>
      {/* {selected_influencers && selected_influencers.length ? (
        <div className="lg:w-3/12 md:w-4/12 w-full order-1 md:!order-2 pl-3">
          {newCampaignWithSelected && (
            <div className="text-center mb-4 shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-bgBodyLight rounded-[8px] p-4 mt-[80px]">
              <Disclosure defaultOpen={true}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="w-full">
                      <div className="flex items-center justify-between w-full">
                        <p className="font-medium text-white">Bulk Actions</p>
                        <FiChevronDown
                          size={20}
                          className={`${
                            open ? "rotate-180 " : ""
                          } transition transform text-white`}
                        />
                      </div>
                    </Disclosure.Button>

                    <Transition
                      enter="transition duration-[500ms] ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-[75ms] ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel>
                        <div className="w-full mt-6">
                          <Button
                            onClick={showInfluencerList}
                            className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex justify-center items-center bg-primary text-white hover:opacity-80 w-full"
                            text="Add to list"
                          />
                        </div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          )}
          <div className="min-h-[85px]">
            <SelectedItems
              newCampaignWithSelected={newCampaignWithSelected}
            />
          </div>
          {campaign_status !== "active" && (
            <p
              className="text-right p-[10px] cursor-pointer underline text-[#9ea1b2]"
              onClick={() => removeAll()}
            >
              Remove All
            </p>
          )}
        </div>
      ) : (
        ""
      )} */}
      <InfluencerListModal
        show={showModal}
        platform={props.platform}
        closeModal={closeInfluenceModalList}
        selectedInfluencers={selected_influencers}
        brandLists={props.brandLists}
        searchBrand={props.searchBrand}
        createBrand={props.addNewBrand}
        addInfluencer={props.addInfluencerToList}
      />
    </>
  );
};

const mapStateToProps = ({
  influencerSearch,
  campaign,
  HeaderReducer,
  brandList,
}) => {
  return {
    isLoading: influencerSearch.isLoading,
    influencers: influencerSearch.influencers,
    platform: influencerSearch.platform,
    payload: influencerSearch.payload,
    form: influencerSearch.form,
    influencerTotal: influencerSearch.influencerTotal,
    selected_influencers: campaign.selected_influencers,
    campaign_status: campaign.form.campaign_status,
    refreshData: HeaderReducer.refreshData,
    brandLists: brandList.brandlists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  const { types: campaigntypes } = require("@store/redux/CampaignRedux");
  const { actions: brandlistactions } = require("@store/redux/BrandListRedux");
  return {
    ...ownProps,
    ...stateProps,
    searchFilters: (payload, form) => {
      actions.searchFilters(dispatch, payload, form);
    },
    searchInfluencers: (data) => {
      actions.searchInfluencers(dispatch, data);
    },
    removeAllInfluencers: () => {
      dispatch({
        type: campaigntypes.HANDLE_REMOVE_ALL_INFLUENCERS,
        data: { type: "discover" },
      });
    },
    fetchBrandLists: (data) => {
      brandlistactions.fetchBrandLists(dispatch, data);
    },
    searchBrand: (data) => {
      brandlistactions.searchBrand(dispatch, data);
    },
    addNewBrand: (data) => {
      brandlistactions.addNewBrand(dispatch, data);
    },
    addInfluencerToList: (data) => {
      brandlistactions.addInfluencersToList(dispatch, data);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(YoutubeItems);
