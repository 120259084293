import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocialListIcons from "../../../../../constants/SocialListIcons";
import { AiFillStar, AiOutlineCheck, AiOutlineStar } from "react-icons/ai";
import { actions, types } from "@store/redux/InfluencerSearchRedux";
import { RxCross1 } from "react-icons/rx";
import { FiEdit } from "react-icons/fi";
import avatar from "@assets/avatar.webp";
import { VscCopy } from "react-icons/vsc";
import { toast } from "react-toastify";

const InfluencerMetaDeta = () => {
  const [form, setForm] = useState({});
  const [originalForm, setOriginalForm] = useState({});
  const [rating, setRating] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isEditing, setIsEditing] = useState({});
  const dispatch = useDispatch();
  const data = useSelector((state) => state.influencerSearch?.influencer);
  const errors = useSelector(
    (state) => state.influencerSearch?.influencerErrors
  );
  const currentList = useSelector((state) => state.brandList.current_list);

  useEffect(() => {
    setForm(data);
    setOriginalForm(data);
    setRating(data?.rating);
  }, [data]);

  const addForm = (e) => {
    let form = Object.assign({}, data);
    form[e.target.name] = e.target.value;
    form["update"] = { key: e.target.name, value: e.target.value };
    setForm(form);
  };

  const handleSaveForm = async () => {
    const result = await actions.saveInfluencer(dispatch, form);
    if (result.status !== 403) {
      setIsEditing({});
    }
  };

  const handleUndo = (key) => {
    dispatch({ type: types.REMOVE_VALIDATION_ERRORS });
    setForm(data);
    setIsEditing({ [key]: false });
  };

  const renderStars = () => {
    const maxRating = 5;
    const stars = [];
    for (let i = 1; i <= maxRating; i++) {
      const starIcon =
        i <= rating ? (
          <AiFillStar
            key={i}
            size={25}
            className="cursor-pointer text-primary"
            onClick={() => handleRating(i)}
          />
        ) : (
          <AiOutlineStar
            key={i}
            size={25}
            className="cursor-pointer hover:text-primary"
            onClick={() => handleRating(i)}
          />
        );

      stars.push(starIcon);
    }
    return stars;
  };

  const handleRating = (value) => {
    setRating(value);
    form["rating"] = value;
    form["update"] = { key: "rating", value: value };
    handleSaveForm();
  };

  const handleEdit = (key) => {
    setForm(originalForm);
    setIsEditing({ [key]: true });
  };

  const handleImageError = (event) => {
    const { currentTarget } = event;
    setErrorCount(errorCount + 1);

    if (errorCount < 3) {
      const fallbackImage =
        data?.profile_picture ||
        `${process.env.REACT_APP_BASE_URL}/images/male_avatar.webp`;
      currentTarget.src = fallbackImage;
    } else {
      currentTarget.src = avatar;
    }
  };

  const handleCopy = (email)=>{
    navigator.clipboard
      .writeText(email)
      .then(() => {
        toast.success(`Email Copied to clipboard`);
      })
      .catch((error) => {
        toast.error(
          "Error while Copied to clipboard"
        );
      })
  }

  return (
    <div className="px-10 pt-5 max-w-[27%] h-[700px] rounded-3xl bg-bgBodyDark shadow-[0px_4px_5px_#e8deea]">
      <div className="grid grid-cols-12 pb-12 border-b-2 border-b-gray-300 border-dashed">
        <div className="col-span-10">
          <div className=" flex flex-col justify-between items-center">
            <img
              src={data?.profile_picture}
              alt={data?.full_name}
              width={50}
              height={50}
              className="rounded-full"
              onError={handleImageError}
            />
            <span className="font-semibold text-white">{data?.full_name}</span>
            <p className="text-white text-sm">@{data?.username}</p>
          </div>
        </div>
        <span className="col-span-1">
          {SocialListIcons(currentList?.list_channel, 30)}
        </span>
      </div>
      <div className="py-5 space-y-5">
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center">
          <span className="text-white">First Name: </span>
          <input
            className="bg-transparent text-white rounded-[8px] h-[28px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary xs:text-[14px] text-[12px] read-only:border-none truncate"
            value={form?.first_name || ""}
            name="first_name"
            maxLength={50}
            readOnly={!isEditing.first_name}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length < 50) addForm(e);
            }}
          />
          {isEditing.first_name ? (
            <>
              <AiOutlineCheck
                className="cursor-pointer mx-2 text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("first_name")}
              />
            </>
          ) : (
            <FiEdit
              // color="gray"
              className="cursor-pointer text-white"
              onClick={() => handleEdit("first_name")}
            />
          )}
        </div>
        {errors && errors.first_name && (
          <p className="red">{errors.first_name[0]}</p>
        )}
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center text-white">
          <span className="text-white">Last Name: </span>
          {isEditing.last_name ? (
            <>
              <input
                className="text-white bg-transparent rounded-[8px] h-[25px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary xs:text-[14px] text-[12px] break-all"
                name="last_name"
                maxLength={50}
                value={form?.last_name || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length < 50) addForm(e);
                }}
              />
              <AiOutlineCheck
                className="cursor-pointer mx-2 text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("last_name")}
              />
            </>
          ) : (
            <>
              <span className="rounded-[8px] w-[60%] items-center px-3 xs:text-[14px] text-[12px] break-all">
                {form?.last_name || ""}
              </span>
              <FiEdit
                // color="gray"
                className="cursor-pointer text-white"
                onClick={() => handleEdit("last_name")}
              />
            </>
          )}
        </div>
        {errors && errors.last_name && (
          <p className="red">{errors.last_name[0]}</p>
        )}
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center text-white">
          <span className="text-white">Email: </span>
          <input
            className="text-white bg-transparent rounded-[8px] h-[25px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary xs:text-[14px] text-[12px] read-only:border-none truncate"
            name="email"
            value={form?.email || ""}
            readOnly={!isEditing.email}
            onChange={(e) => addForm(e)}
            autoComplete="off"
          />
          {isEditing.email ? (
            <>
              <AiOutlineCheck
                className="cursor-pointer text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("email")}
              />
            </>
          ) : (
            <>
              <FiEdit
                // color="gray"
                className="cursor-pointer text-white"
                onClick={() => handleEdit("email")}
              />
              {
                form?.email && 
                <VscCopy
                  // color="gray"
                  size={17}
                  className="cursor-pointer -ml-2 text-white"
                  onClick={() => handleCopy(form?.email)}
                />
              }
            </>
          )}
        </div>
        {errors && errors.email && <p className="red">{errors.email[0]}</p>}
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center text-white">
          <span className="text-white">Phone: </span>
          <input
            className="bg-transparent rounded-[8px] h-[25px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary xs:text-[14px] text-[12px] read-only:border-none truncate"
            name="phone"
            type="number"
            value={form?.phone || ""}
            readOnly={!isEditing.phone}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length < 13) addForm(e);
            }}
          />
          {isEditing.phone ? (
            <>
              <AiOutlineCheck
                className="cursor-pointer mx-2 text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("phone")}
              />
            </>
          ) : (
            <FiEdit
              // color="gray"
              className="cursor-pointer text-white"
              onClick={() => handleEdit("phone")}
            />
          )}
        </div>
        {errors && errors.phone && <p className="red">{errors.phone[0]}</p>}
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center text-white">
          <span className="text-white">PayPal ID: </span>
          <input
            className="bg-transparent rounded-[8px] h-[25px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary xs:text-[14px] text-[12px] read-only:border-none truncate"
            name="paypal_email"
            value={form?.paypal_email || ""}
            readOnly={!isEditing.paypal_email}
            onChange={(e) => addForm(e)}
          />
          {isEditing.paypal_email ? (
            <>
              <AiOutlineCheck
                className="cursor-pointer mx-2 text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("paypal_email")}
              />
            </>
          ) : (
            <FiEdit
              // color="gray"
              className="cursor-pointer text-white"
              onClick={() => handleEdit("paypal_email")}
            />
          )}
        </div>
        {errors && errors.paypal_email && (
          <p className="red">{errors.paypal_email[0]}</p>
        )}
        <div className="bg-bgBodyDark p-3 rounded-xl flex gap-x-3 items-center text-white">
          <span className="text-white pr-8">Rating: </span>
          {renderStars()}
        </div>
        <div className="bg-bgBodyDark p-3 rounded-xl flex justify-between items-center text-white">
          <span className="text-white">Fixed Pay: </span>
          <input
            className="bg-transparent rounded-[8px] h-[25px] w-[60%] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:primary xs:text-[14px] text-[12px] read-only:border-none truncate focus:border-primary"
            name="fixed_pay"
            type="number"
            value={form?.fixed_pay || ""}
            readOnly={!isEditing.fixed_pay}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length < 10) addForm(e);
            }}
          />
          {isEditing.fixed_pay ? (
            <>
              <AiOutlineCheck
                className="cursor-pointer mx-2 text-white"
                // color="gray"
                onClick={handleSaveForm}
              />
              <RxCross1
                className="cursor-pointer text-white"
                // color="gray"
                onClick={() => handleUndo("fixed_pay")}
              />
            </>
          ) : (
            <FiEdit
              // color="gray"
              className="cursor-pointer text-white"
              onClick={() => handleEdit("fixed_pay")}
            />
          )}
        </div>
        {errors && errors.fixed_pay && (
          <p className="red">{errors.fixed_pay[0]}</p>
        )}
      </div>
    </div>
  );
};

export default InfluencerMetaDeta;
