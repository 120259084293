import BrandJoin from "@container/brands/auth/BrandJoin";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function BrandJoinScreen() {
	const params = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		let token = localStorage.getItem("access-token");
		if (token) {
			navigate('/permission/invitation');
		}
	}, [])

	return <BrandJoin params={params} navigate={navigate} />;
}
