import { Component } from "react";
import SettingHeader from "@components/BrandSettings/SettingHeader";
import SettingBrandIntegrationTopTab from "@components/SettingBrandIntegrationTopTab";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import Button from "@components/global/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsClipboardCheck } from "react-icons/bs";
import Swal from "sweetalert2";
import SubHeader from "@components/Header/SubHeader";

class SettingBrandCredentials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showKey: false,
      showSecret: false,
      keyType: "password",
      secretType: "password",
      ip: "",
    };
  }

  async componentDidMount() {
    await this.props.fetchApiCredentials();
    this.setState({ ip: this.props.clientCredentials?.ip_address || "" });
  }

  handleGenerateKeys = () => {
    if (
      this.props.refreshData &&
      this.props.refreshData.identifier !== "agency_month" &&
      this.props.refreshData.identifier !== "basic_month" &&
      this.props.refreshData.identifier !== "basic_year" &&
      this.props.refreshData.identifier !== "master_month" &&
      this.props.refreshData.identifier !== "master_year"
    ) {
      Swal.fire({
        title: "Upgrade Your Plan!",
        text: "To generate API Credentials, please upgrade to the Elite Plan.",
        showCloseButton: true,
        confirmButtonText: "Upgrade Plan",
        confirmButtonColor: "#7c3292",
        dangerMode: true,
        customClass: {
          actions: "flex-row-reverse",
          closeButton: "hover:text-[#7c3292]",
          confirmButton: "hover:!shadow-none focus:!shadow-none min-w-[100px]",
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.value) {
          this.props.navigate("/billing");
        }
      });
    } else {
      this.props.generateApiCredentials();
    }
  };

  isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  render() {
    if (localStorage.getItem("role") !== "brand") {
      window.location.href = "/";
    }

    const {
      clientCredentials,
      isGeneratingApiCredentials,
      changeIPAddress,
      regenerateSecretKey,
      isChangingIP,
    } = this.props;

    const { keyType, showKey, secretType, showSecret, ip } = this.state;

    return (
      <>
        <SubHeader title={"API Credentials"} />
        <div className="setting-tab-navigation">
          <SettingHeader />
          <SettingBrandIntegrationTopTab />
          <div className="mb-12">
            <div className="grid grid-cols-12 gap-5">
              <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                <SettingBrandSidebar />
              </div>
              <div className="md:col-span-9 col-span-12 mt-12 md:!mt-0">
                <h4 className="font-semibold text-white mb-4 text-[20px]">
                  API Credentials
                </h4>
                <div className="shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-bgBodyLight rounded-[8px] p-3 sm:!p-12 mb-12">
                  {Object.entries(clientCredentials || {}).length > 0 ? (
                    <div class="w-full space-y-7">
                      <div class="flex flex-wrap gap-x-10 items-center -mx-3 mb-2">
                        <span className="text-lg font-medium text-white">
                          Username:{" "}
                        </span>
                        <input
                          type={keyType}
                          name="password"
                          value={clientCredentials?.api_key}
                          disabled
                          className="pr-12 rounded-[8px] h-[35px] inline-flex w-[200px] items-center pl-3 border-[1px] text-white border-[#ced4da]"
                        />
                        <div className="flex gap-x-3 items-center">
                          {showKey ? (
                            <AiOutlineEyeInvisible
                              size={22}
                              onClick={() =>
                                this.setState({
                                  keyType: "password",
                                  showKey: false,
                                })
                              }
                              className="cursor-pointer text-white"
                            />
                          ) : (
                            <AiOutlineEye
                              size={22}
                              onClick={() =>
                                this.setState({
                                  keyType: "text",
                                  showKey: true,
                                })
                              }
                              className="cursor-pointer text-white"
                            />
                          )}
                          <BsClipboardCheck
                            size={18}
                            onClick={() =>
                              navigator.clipboard
                                .writeText(clientCredentials?.api_key)
                                .then(() => {
                                  toast.success(`Copied to clipboard`);
                                })
                                .catch((error) => {
                                  toast.error(
                                    "Error while Copied to clipboard"
                                  );
                                })
                            }
                            className="cursor-pointer text-white"
                          />
                        </div>
                      </div>
                      <div class="flex flex-wrap gap-x-10 items-center -mx-3 mb-2">
                        <span className=" text-white pr-1">Password: </span>
                        <input
                          type={secretType}
                          disabled
                          name="password"
                          autoComplete="off"
                          value={clientCredentials?.api_secret}
                          className="pr-5 rounded-[8px] h-[35px] inline-flex w-[330px] items-center pl-3 border-[1px] text-white border-[#ced4da]"
                        />
                        <div className="flex gap-x-3 items-center">
                          {showSecret ? (
                            <AiOutlineEyeInvisible
                              size={22}
                              onClick={() =>
                                this.setState({
                                  secretType: "password",
                                  showSecret: false,
                                })
                              }
                              className="cursor-pointer text-white"
                            />
                          ) : (
                            <AiOutlineEye
                              size={22}
                              onClick={() =>
                                this.setState({
                                  secretType: "text",
                                  showSecret: true,
                                })
                              }
                              className="cursor-pointer text-white"
                            />
                          )}
                          <BsClipboardCheck
                            size={18}
                            onClick={() =>
                              navigator.clipboard
                                .writeText(clientCredentials?.api_secret)
                                .then(() => {
                                  toast.success(`Copied to clipboard`);
                                })
                                .catch(() => {
                                  toast.error(
                                    "Error while Copied to clipboard"
                                  );
                                })
                            }
                            className="cursor-pointer text-white"
                          />
                        </div>
                        <Button
                          disabled={isGeneratingApiCredentials}
                          onClick={regenerateSecretKey}
                          className="px-8 rounded-[8px] mt-5 h-[35px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80  disabled:opacity-70"
                          text={"Regenerate Password"}
                          suffix={
                            isGeneratingApiCredentials && (
                              <FaSpinner
                                className="ml-3 animate-[spin_2s_linear_infinite]"
                                size={20}
                              />
                            )
                          }
                        />
                      </div>
                      <div class="flex flex-wrap gap-x-9 -mx-3 mb-2">
                        <span className=" text-white pt-2">IP Address: </span>
                        <div>
                          <input
                            type="text"
                            placeholder="192.168.1.1"
                            value={ip}
                            onChange={(e) =>
                              this.setState({ ip: e.target.value })
                            }
                            className="rounded-[8px] bg-transparent text-white h-[35px] inline-flex w-[330px] items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                          />
                          <p className="text-sm text-gray-400">
                            Note: You cannot access APIs without IP Address.
                          </p>
                        </div>
                        <Button
                          disabled={this.isEmptyOrSpaces(ip) || isChangingIP}
                          onClick={() => changeIPAddress({ ipAddress: ip })}
                          className="px-8 rounded-[8px] h-[35px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80  disabled:opacity-70"
                          text={"Save Changes"}
                          suffix={
                            isChangingIP && (
                              <FaSpinner
                                className="ml-3 animate-[spin_2s_linear_infinite]"
                                size={20}
                              />
                            )
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="items-center justify-center flex">
                      <Button
                        disabled={isGeneratingApiCredentials}
                        onClick={this.handleGenerateKeys}
                        className="px-12 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80  disabled:opacity-70"
                        text={"Generate API Keys"}
                        suffix={
                          isGeneratingApiCredentials && (
                            <FaSpinner
                              className="ml-3 animate-[spin_2s_linear_infinite]"
                              size={20}
                            />
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ settings, HeaderReducer }) => {
  return {
    clientCredentials: settings.clientCredentials,
    isGeneratingApiCredentials: settings.isGeneratingApiCredentials,
    isChangingIP: settings.isChangingIP,
    refreshData: HeaderReducer.refreshData,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("../../../store/redux/SettingRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchApiCredentials: () => {
      return actions.fetchApiCredentials(dispatch);
    },
    generateApiCredentials: () => {
      return actions.generateApiCredentials(dispatch);
    },
    regenerateSecretKey: () => {
      return actions.regenerateSecretKey(dispatch);
    },
    changeIPAddress: (query) => {
      return actions.changeIPAddress(dispatch, query);
    },
  };
};

export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(SettingBrandCredentials);
