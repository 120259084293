import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Items from "@components/BrandInfluencerDiscover/Items/Items";
import Sort from "@components/BrandInfluencerDiscover/Filters/Sort";
import Loader from "@components/global/Loader";
import Emitter from "../../../constants/Emitter";
import InfluencerListModal from "@components/InfluencerListModal";
import Pagination from "@components/Pagination";
import CustomLoader from "@components/global/CustomLoader";

const InstagramItems = (props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    removeAll();
  }, []);

  const onPageChanged = (pageData) => {
    // if (props.refreshData.is_admin) {
    const payload = Object.assign({}, props.payload);
    const form = Object.assign({}, props.form);
    payload["paging"]["skip"] =
      (pageData?.currentPage || 0) * (pageData?.pageLimit || 0) -
      pageData?.pageLimit;
    form["loadMore"] = true;
    props.searchFilters(payload, form);
    let query = {
      platform: props.platform,
      payload: payload,
      isCreditDeduct: true,
      override_filter: true,
    };
    props.searchInfluencers(query);
    // } else {
    //   Emitter.emit("PERMISSION_POPUP");
    // }
  };

  const removeAll = () => {
    const { removeAllInfluencers } = props;
    removeAllInfluencers();
  };

  const closeInfluenceModalList = () => {
    setShowModal(false);
  };

  const showInfluencerList = () => {
    if (props.refreshData.is_admin) {
      setShowModal(true);
      let query = {
        platform: props.platform,
      };
      props.fetchBrandLists(query);
    } else {
      Emitter.emit("PERMISSION_POPUP");
    }
  };

  const {
    isLoading,
    influencers,
    form,
    selected_influencers,
    newCampaignWithSelected,
    campaign_status,
    influencerTotal,
  } = props;

  return (
    <>
      <div className="w-full pr-3">
        <div className="pb-12 relative">
          {form.loadMore && influencers.length === 0 ? (
            <div className="py-12">
              <CustomLoader />
            </div>
          ) : (
            <div>
              {influencers && influencers.length > 0 ? (
                <>
                  <Sort platform={props.platform} showInfluencerList={showInfluencerList} removeAll={removeAll} newCampaignWithSelected={newCampaignWithSelected} />
                  <div className="grid grid-cols-12 gap-5">
                    {influencers.map((influencer, index) => (
                      <div
                        className="mb-4 col-span-12"
                        key={index}
                      >
                        <Items
                          influencer={influencer}
                          newCampaignWithSelected={newCampaignWithSelected}
                          platform={"instagram"}
                          showInfluencerList={showInfluencerList}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="text-center w-full py-[5rem] px-[1rem] justify-center text-[#bbb] text-[2.8rem] font-medium leading-[40px]">
                  We have nothing to show you here.
                </div>
              )}
            </div>
          )}
        </div>

        <div className="inline-flex items-center justify-center">
          {!isLoading && (influencerTotal || 0) > 10 && (
            <Pagination
              totalRecords={Math.min(influencerTotal || 0, 10000) - 10}
              pageLimit={10}
              pageNeighbours={10}
              onPageChanged={onPageChanged}
            />
          )}

          {influencers &&
            influencers.length > 0 &&
            !isLoading &&
            form.loadMore && <Loader color={'text-white'} size="30" />}
        </div>
      </div>
      <InfluencerListModal
        show={showModal}
        platform={props.platform}
        closeModal={closeInfluenceModalList}
        selectedInfluencers={selected_influencers}
        brandLists={props.brandLists}
        searchBrand={props.searchBrand}
        createBrand={props.addNewBrand}
        addInfluencer={props.addInfluencerToList}
      />
    </>
  );
};

const mapStateToProps = ({
  influencerSearch,
  campaign,
  HeaderReducer,
  brandList,
}) => {
  return {
    isLoading: influencerSearch.isLoading,
    influencers: influencerSearch.influencers,
    platform: influencerSearch.platform,
    payload: influencerSearch.payload,
    form: influencerSearch.form,
    influencerTotal: influencerSearch.influencerTotal,
    selected_influencers: campaign.selected_influencers,
    campaign_status: campaign.form.campaign_status,
    refreshData: HeaderReducer.refreshData,
    brandLists: brandList.brandlists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  const { types: campaigntypes } = require("@store/redux/CampaignRedux");
  const { actions: brandlistactions } = require("@store/redux/BrandListRedux");
  return {
    ...ownProps,
    ...stateProps,
    searchFilters: (payload, form) => {
      actions.searchFilters(dispatch, payload, form);
    },
    searchInfluencers: (data) => {
      actions.searchInfluencers(dispatch, data);
    },
    removeAllInfluencers: () => {
      dispatch({
        type: campaigntypes.HANDLE_REMOVE_ALL_INFLUENCERS,
        data: { type: "discover" },
      });
    },
    fetchBrandLists: (data) => {
      brandlistactions.fetchBrandLists(dispatch, data);
    },
    searchBrand: (data) => {
      brandlistactions.searchBrand(dispatch, data);
    },
    addNewBrand: (data) => {
      brandlistactions.addNewBrand(dispatch, data);
    },
    addInfluencerToList: (data) => {
      brandlistactions.addInfluencersToList(dispatch, data);
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(InstagramItems);
