import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch } from "@headlessui/react";
import Tooltip from "@components/global/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import tooltip from "../../../constants/tooltip";
import { Popover, Transition } from "@headlessui/react";
import { MdEmail } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:
        this.props.payload.filter.with_contact.filter(
          (i) => i.type === "email" && i.action === "should"
        ).length > 0,
    };
  }

  requestInfluencerCount = (data) => {
    let payload = Object.assign({}, data);
    const actions = Object.assign([], this.props.actions);
    if (payload.filter.account_type) {
      if (payload.filter.account_type.includes("2")) {
        payload = {
          ...payload,
          filter: {
            ...payload.filter,
            account_type: [],
          },
        };
      }
      if (payload.filter.account_type.includes("3")) {
        payload = {
          ...payload,
          filter: {
            ...payload.filter,
            account_type: [],
          },
        };
      }
      if (payload.filter.account_type.includes("1")) {
        payload = {
          ...payload,
          filter: {
            ...payload.filter,
            account_type: [1, 3],
          },
        };
      }
    }
    if (actions.length > 0) {
      payload = {
        ...payload,
        filter: {
          ...payload.filter,
          actions: actions,
        },
      };
    }
    let query = {
      platform: this.props.platform,
      payload: payload,
    };

    this.props.searchInfluencersCount(query);
  };

  addInfluencerActions = (key, email) => {
    let checked = false;
    // this.setState({ email: email });
    checked = email;

    const payload = Object.assign({}, this.props.payload);
    const data = {
      payload: { type: key, action: checked ? "should" : "not" },
      checked: checked,
      key: key,
    };
    payload["filter"]["with_contact"] = [data.payload];
    this.props.InfluencerActions(data);

    setTimeout(() => {
      this.requestInfluencerCount(payload);
    }, 1000);
  };

  render() {
    let { payload } = this.props;
    const emailFilter =
      payload.filter.with_contact?.filter(
        (i) => i.type == "email" && i.action == "should"
      ).length > 0;

    return (
      <label
        htmlFor="email"
        className="cursor-pointer flex shrink-0 text-white items-center text-[15px] font-normal"
      >
        <input
          id="email"
          type="checkbox"
          checked={emailFilter}
          onChange={() =>
            this.addInfluencerActions(
              "email",
              emailFilter ? false : true
            )
          }
          className="hidden peer"
        />
        <span className="mr-3 peer-checked:bg-primary bg-white h-[20px] w-[20px] before:content-[''] relative before:absolute before:bottom-[7.2px] before:left-[3px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-[#979797] rounded-md"></span>
        Only show influencers with email
      </label>
    );
  }
}

const mapStateToProps = ({ influencerSearch }) => {
  return {
    form: influencerSearch.form,
    platform: influencerSearch.platform,
    payload: influencerSearch.payload,
    actions: influencerSearch.actions,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  return {
    ...ownProps,
    ...stateProps,
    searchInfluencersCount: (data) => {
      actions.searchInfluencersCount(dispatch, data);
    },
    InfluencerActions: (data) => actions.influencerActions(dispatch, data),
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Email);
