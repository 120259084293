import React, { Component } from "react";
import TopHeader from "@components/BrandInfluencerList/TopHeader";
import Filters from "@components/BrandInfluencerList/Filters";
import Lists from "@components/BrandInfluencerList/Lists";
import SubHeader from "@components/Header/SubHeader";

class BrandInfluencersInstagramList extends Component {
	render() {
		return (
			<>
        		<SubHeader title={'List Influencers Instagram'} />
				<div className="mb-12">
					<div className="py-[20px] mb-0">
						<TopHeader />
						<div className="bg-[#ddd] h-[1px] w-full my-6" />
						<Filters />
					</div>
					<div className="mt-12">
						<Lists />
					</div>
				</div>
			</>
		);
	}
}

export default BrandInfluencersInstagramList;
