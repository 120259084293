import MaleAge from "@assets/svgs/male_age.webp";
import AltMaleAge from "@assets/svgs/male_age_alt.webp";
import FemaleAge from "@assets/svgs/female_age.webp";
import AltFemaleAge from "@assets/svgs/female_age_alt.webp";
import { useSelector } from "react-redux";

export default function AudienceGender() {
  const influencer = useSelector((state) => state.influencerSearch?.influencer);
  const audienceGenders =
    influencer?.report?.audience_followers?.data?.audience_genders;
  const male = (audienceGenders || []).filter((i) => i.code === "MALE")[0];
  const female = (audienceGenders || []).filter((i) => i.code === "FEMALE")[0];
  const maleValue = ((male?.weight || 0) * 100).toFixed(1);
  const femaleValue = ((female?.weight || 0) * 100).toFixed(1);

  return (
    <div className="p-4 bg-bgBodyDark border-[1px] border-[#dee2e6] h-full rounded-[8px]">
      <div className="flex justify-between">
        <h5 className="font-normal text-[16px] text-white">
          Audience Gender
        </h5>
      </div>
      <div className="flex flex-wrap justify-center items-end mt-12 pt-3">
        <div className="flex w-7/12 justify-center mb-6 pr-4">
          <div className="inline-flex items-end relative mr-6">
            <img src={FemaleAge} alt="male age" className="h-[200px]" />
            <div
              className="absolute w-full overflow-hidden"
              style={{
                height: `${femaleValue}%`,
              }}
            >
              <img
                src={AltFemaleAge}
                className="absolute bottom-0 h-[200px]"
                alt="male age"
              />
            </div>
          </div>
          <div className="inline-flex items-end relative">
            <img src={MaleAge} alt="male age" className="h-[200px]" />
            <div
              className="absolute w-full overflow-hidden"
              style={{
                height: `${maleValue}%`,
              }}
            >
              <img
                src={AltMaleAge}
                className="absolute bottom-0 h-[200px]"
                alt="male age"
              />
            </div>
          </div>
        </div>
        <div className="md:hidden w-full"></div>
        <div className="">
          <div className="flex mb-4">
            <p className="text-[14px] font-normal w-24 text-white">FEMALE</p>
            <p className="text-[16px] pink font-normal">{femaleValue} %</p>
          </div>
          <div className="flex">
            <p className="text-[14px] font-normal w-24 text-white">MALE</p>
            <p className="text-[16px] purple font-normal">{maleValue} %</p>
          </div>
        </div>
      </div>
    </div>
  );
}
