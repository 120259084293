import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "@assets/btrendy-new-logo.webp";
import { connect } from "react-redux";
import { HANDLE_LOGOUT_SUBMIT } from "@store/reducers/LoginReducer";
import * as headerActions from "@store/actions/HeaderActions";
import { NavLink } from "react-router-dom";
import Addbrand from "./AddBrand";
import moment from "moment";
import { Disclosure, Popover } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import Btrendy from "@constants/Btrendy";
import Loader from "@components/global/Loader";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import tiktok from "../../assets/images/tiktok.png";
import youtube from "../../assets/images/youtube.png";
import instagram from "../../assets/images/instagram.png";
import letter from "../../assets/images/letter.png";
import followers from "../../assets/images/followers.png";
import neonCompass from "../../assets/images/neon-compass.png";
import saved from "../../assets/images/saved.png";
import share from "../../assets/images/share.png";
import artificialIntelligence from "../../assets/images/artificial-intelligence.png";
import PM from "../../assets/images/project-management.png";
import avatar from "@assets/avatar.webp";
import Tooltip from "@components/global/Tooltip";
import { FiPlus } from "react-icons/fi";
import { MdLock } from "react-icons/md";
import { PiCirclesThreePlus, PiDoorOpen } from "react-icons/pi";

class BrandHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.addbrandRef = React.createRef();
  }

  componentDidMount() {
    this.props.currentLoggedInUser("brand");
    this.props.refreshReports();
    const data = {
      main_account: localStorage.getItem("main_account"),
    };

    this.props.fetchInfluencerSubAccount(data);
    document.querySelector("body").addEventListener("click", setScroll);
    function setScroll() {
      let state = document.querySelector(
        "#preserve_scroll button[data-headlessui-state=open]"
      );
      if (state) {
        document.querySelector("html").style.overflow = "hidden";
      } else {
        document.querySelector("html").style.overflow = "";
      }
    }
  }

  switchAccount = (id) => {
    const data = {
      id: id,
      main_account: localStorage.getItem("main_account"),
    };
    this.props.switchAccount(data);
  };

  influencerSwitchAccount = (id) => {
    const data = {
      id: id,
      main_account: localStorage.getItem("main_account"),
    };
    this.props.switchAccount(data);
  };

  endTrialNow = async () => {
    this.setState({ loading: true });
    await Btrendy.subscriptionEndTrial();
    setTimeout(() => {
      this.refreshReports();
    }, 4000);
  };

  cancelSubscriptionNow = async () => {
    this.setState({ loading: true });
    await Btrendy.cancelSubscriptionNow({});
    setTimeout(() => {
      this.refreshReports();
    }, 4000);
  };

  refreshReports = async () => {
    await this.props.refreshReports();
    this.setState({ loading: false });
  };

  render() {
    const { refreshData,currentLoggedUser,planName,mainAccount,user,subAccounts,remainingCredits } = this.props;
    const boxShadowStyle = {
      boxShadow:
        "0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)",
    };
    return (
      <div
        style={boxShadowStyle}
        className={`h-screen flex-col justify-between lg:flex hidden border-white border-r fixed top-0 ${this.props.hideShowSideBar() ? "-left-[280px]" : "left-0"} !w-[280px] z-[1000] bg-bgBody transition-all duration-200 ease-in`}
      >
        <div>
          <div className="pt-5 px-8 mb-2">
            <Link to="/dashboard">
              <img className="w-[160px]" src={logo} alt="alt" />
            </Link>
          </div>
          <div className="w-full h-[calc(100vh-180px)] overflow-y-auto">
            {refreshData?.on_trial &&
              refreshData?.subscription?.trial_ends_at && (
                <div
                  className="hidden justify-center items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
                  role="alert"
                >
                  <svg
                    className="flex-shrink-0 inline w-4 h-4 mr-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  <div>
                    <span className="font-medium">
                      Your trial ends in{" "}
                      {moment(refreshData?.subscription?.trial_ends_at)
                        .add("day", 1)
                        .diff(moment(), "days")}{" "}
                      day's, and will renew automatically
                    </span>
                    .
                    {this.state.loading ? (
                      <Loader className="ml-2 inline-flex items-center" />
                    ) : (
                      <>
                        <button
                          onClick={() => this.endTrialNow()}
                          type="button"
                          className="ml-2 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-300 dark:text-gray-800 dark:hover:bg-blue-400 dark:focus:ring-blue-800"
                        >
                          start subscription immediately
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}

            <div className="px-[1rem]">
              <div className="flex flex-wrap items-center py-6 md:gap-x-10 gap-y-5">
                <div className="flex items-center grow md:space-x-10">
                  <nav className="hidden flex-col space-y-3 lg:flex grow">
                    <NavLink
                      to="/discovery/instagram"
                      className={"mb-1 flex items-start p-3 rounded-lg  ml-0"}
                    >
                      <span className="flex items-center gap-2">
                        <img
                          src={neonCompass}
                          alt="Compass logo"
                          className="h-6"
                        />
                        <p className="text-[14px] font-medium text-white">
                          Discover
                        </p>
                      </span>
                    </NavLink>
                    <div className="w-full">
                      <div className="mx-auto w-full">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between items-center gap-3 rounded-lg  px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span className="flex items-center justify-start gap-2">
                                  <img
                                    src={followers}
                                    alt="followers logo"
                                    className="h-5"
                                  />
                                  <span className="font-normal shrink-0 text-lg">
                                    Followers Scanner
                                  </span>
                                </span>
                                <IoIosArrowDown
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5 text-white`}
                                />
                              </Disclosure.Button>
                              <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel className="px-4 flex flex-col pb-2 pt-4 items-start">
                                  <NavLink
                                    to="/influencial/instagram"
                                    className={
                                      "mb-1 flex items-start p-3 rounded-lg  ml-3 gap-3"
                                    }
                                  >
                                    <img
                                      src={instagram}
                                      alt="tiktok logo"
                                      className="h-6"
                                    />
                                    <p className="text-[14px] font-medium text-white">
                                      Instagram
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/influencial/youtube"
                                    className={
                                      "mb-1 flex items-start p-3 rounded-lg  ml-3 gap-3"
                                    }
                                  >
                                    <img
                                      src={youtube}
                                      alt="tiktok logo"
                                      className="h-6"
                                    />
                                    <p className="text-[14px] font-medium text-white">
                                      Youtube
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/influencial/tiktok"
                                    className={
                                      "mb-1 flex items-start p-3 rounded-lg ml-3 justify-center gap-3"
                                    }
                                  >
                                    <img
                                      src={tiktok}
                                      alt="tiktok logo"
                                      className="h-6"
                                    />
                                    <p className="text-[14px] font-medium text-white">
                                      Tiktok
                                    </p>
                                  </NavLink>
                                  <NavLink
                                    to="/influencial/email-match"
                                    className={
                                      "mb-1 flex items-start p-3 rounded-lg  ml-3 gap-3"
                                    }
                                  >
                                    <img
                                      src={letter}
                                      alt="tiktok logo"
                                      className="h-6"
                                    />
                                    <p className="text-[14px] font-medium text-white">
                                      Email Match
                                    </p>
                                  </NavLink>
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                    <NavLink
                      to="/list/instagram"
                      className={
                        "mb-1 flex items-start p-3 rounded-lg  ml-0 gap-2"
                      }
                    >
                      <img src={saved} alt="saved logo" className="h-6" />
                      <p className="text-[14px] font-medium text-white">Lists</p>
                    </NavLink>
                    <NavLink
                      to="/export"
                      className={
                        "mb-1 flex items-start p-3 rounded-lg ml-1 gap-2"
                      }
                    >
                      <img src={share} alt="export logo" className="h-5" />
                      <p className="text-[14px] font-medium text-white">
                        Exports
                      </p>
                    </NavLink>
                    <NavLink
                      to="/past-report/instagram"
                      className={"mb-1 flex items-start p-3 rounded-lg gap-2"}
                    >
                      <img
                        src={artificialIntelligence}
                        alt="analyzer logo"
                        className="h-6"
                      />
                      <p className="text-[14px] font-medium text-white">
                        Analyzer
                      </p>
                    </NavLink>

                    <NavLink
                      to="/reports"
                      className="flex w-full  items-center  rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 gap-2"
                    >
                      <img src={PM} alt="tracking logo" className="h-7" />
                      <span className="font-normal text-lg">Tracking</span>
                    </NavLink>
                    <div className="w-full hidden ">
                      <div className="mx-auto w-full">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full justify-between items-center gap-3 rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span className="font-normal text-lg">
                                  Assets
                                </span>
                                <IoIosArrowDown
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-5 w-5 text-white`}
                                />
                              </Disclosure.Button>
                              <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel className="px-4 flex flex-col pb-2 pt-4">
                                  <NavLink
                                    to="/products"
                                    className={
                                      "mb-1 flex items-start p-3 rounded-lg hover:bg-primary/20 ml-3"
                                    }
                                  >
                                    <p className="text-[14px] font-medium text-white">
                                      Products
                                    </p>
                                  </NavLink>
                                </Disclosure.Panel>
                              </Transition>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                    <NavLink
                      to="/account"
                      className="flex w-full  items-center  rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 gap-2"
                    >
                      <span className="font-normal text-lg">Account details</span>
                    </NavLink>
                    <NavLink
                      to="/permission"
                      className="flex w-full  items-center  rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 gap-2"
                    >
                      <span className="font-normal text-lg">Workspaces</span>
                    </NavLink>
                    <NavLink
                      to="/billing"
                      className="flex w-full  items-center  rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 gap-2"
                    >
                      <span className="font-normal text-lg">Billing</span>
                    </NavLink>
                    <NavLink
                      to="/integration"
                      className="flex w-full  items-center  rounded-lg px-4 py-2 text-left text-sm font-medium text-white focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 gap-2"
                    >
                      <span className="font-normal text-lg">Integrations</span>
                    </NavLink>
                  </nav>
                </div>
              </div>
            </div>
            <Addbrand ref={this.addbrandRef} />
          </div>
        </div>
        <div className="px-[1rem] pb-3 flex justify-between w-full items-center">
          <div className="flex items-center gap-x-3"> 
            <img
              className="logo rounded-full border-white border w-[32px] h-[32px]"
              alt="logo"
              src={
                  currentLoggedUser &&
                      currentLoggedUser.avatar
                      ? currentLoggedUser.avatar
                      : avatar
              }
            />
            <h1 className="text-white text-base">
              {currentLoggedUser &&
                currentLoggedUser.name
                ? currentLoggedUser.name
                : ""}
            </h1>
          </div>
         
          <Popover className="flex items-center relative">
              <Popover.Button className="bg-bgBodyLight p-2 rounded focus-visible:outline-0">
                <IoIosArrowUp size={20} className="text-[#7DA1F6]" />
              </Popover.Button>
              <Transition
                  as={React.Fragment}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel className="absolute w-screen left-12 shadow-[0px_0px_2px_0px_#ffffff] z-10 lg:max-w-[38rem] md:max-w-[560px] sm:max-w-[450px] max-w-[320px] rounded-md" style={{
					transformOrigin: "top right",
					bottom: "1rem",
                }}>
					{({ close }) => (
						<div className="rounded-[8px] bg-bgBody text-white py-3 ">
							<div className="flex justify-between">
								<div className="flex flex-col pl-2 space-y-1">
									<p className="text-lg">Plan Name: {planName}</p>
									<Link
										to={
											refreshData.is_main
												? "/billing/credit"
												: "/history/credits-history"
										}
										className="ml-0 gap-x-1 flex items-center justify-start"
									>
										<span className="text-white text-base">
											Credits:
										</span>
										<span className="text-white text-base font-medium">
											{parseInt(remainingCredits)}
										</span>
									</Link>
									<p className="text-white text-sm">Main Account</p>
								</div>
								<div className="flex flex-col items-end px-2 space-y-1">
									{/* <button
										onClick={close}
										className="bg-transparent inline-block border-0"
									>
										<NavLink
											to="/account"
											className="text-white hover:success"
										>
											<u className="text-[14px] inline-block">
												Account
											</u>
										</NavLink>
									</button> */}
									{refreshData &&
										refreshData.is_main &&
										!refreshData.is_appsumo ? (
										<button
											onClick={close}
											className="bg-transparent"
										>
											<NavLink
												className=" text-white hover:success"
												to="/billing"
											>
												<u className="text-[14px] inline-block">
													Manage Plan
												</u>
											</NavLink>
										</button>
									) : (
										""
									)}
									{refreshData.is_main &&
										refreshData.planName !== "Free" && (
											<button
												onClick={close}
												className="bg-transparent"
											>
												<NavLink
													to="/billing/credit"
													className="text-white hover:success"
												>
													<u className="text-[14px] inline-block">
														Credits top-up
													</u>
												</NavLink>
											</button>
										)}
								</div>
							</div>
							<div className="pl-2 flex items-center mt-5 gap-x-3">
								<img
									className="border-2 rounded-full border-primary p-1 w-[40px] h-[40px]"
									alt="logo"
									src={
										currentLoggedUser.avatar
											? currentLoggedUser.avatar
											: avatar
									}
								/>
								<div>
									<Tooltip
										trigger={
											<h4 className="text-white text-base truncate font-medium">
												{currentLoggedUser &&
													currentLoggedUser.name
													? currentLoggedUser.name
													: ""}
											</h4>
										}
										tooltipText={
											currentLoggedUser &&
												currentLoggedUser.name
												? currentLoggedUser.name
												: ""
										}
										placement="top-left"
									/>
									<span className="text-[12px] lightDark">
										{currentLoggedUser &&
											currentLoggedUser.city
											? currentLoggedUser.city[
											"label"
											] + ","
											: ""}
										{currentLoggedUser &&
											currentLoggedUser.country
											? currentLoggedUser.country[
											"label"
											]
											: ""}
									</span>
								</div>
							</div>
							<div className="max-h-[200px] border-b overflow-y-auto">
								{mainAccount && mainAccount.id && (
									<div
										className={`flex items-center py-3 px-[1rem] ${user.id === mainAccount.id &&
											"bg-bgBody"
											}`}
									>
										{user.id !== mainAccount.id ? (
											<>
												<img
													className="mr-4 object-cover rounded-full w-[38px] h-[38px]"
													src={
														refreshData.is_main
															? currentLoggedUser.avatar ||
															avatar
															: mainAccount.profile_pic
																? process.env.REACT_APP_AWS_URl +
																"/" +
																mainAccount.profile_pic
																: avatar
													}
													alt={mainAccount.name}
												/>
												<div>
													<h6 className="font-medium text-[13px] mb-1">
														{refreshData.is_main
															? currentLoggedUser.name
															: mainAccount.name}
													</h6>
												</div>
												<button
													className="px-[1rem] inline-flex items-center justify-center whitespace-nowrap rounded-[8px] border-0 text-[12px] bg-primary font-medium text-white hover:opacity-80 h-[35px] tracking-wider ml-auto"
													onClick={() =>
														this.switchAccount(mainAccount.id)
													}
												>
													Switch
												</button>
											</>
										) : null}
									</div>
								)}
								{subAccounts && subAccounts.length > 0 && (
									<div className="py-3">
										<p className="text-white text-base ml-4">Workspaces</p>
									</div>
								)}
								{subAccounts &&
									subAccounts.length > 0 &&
									subAccounts.map((account, key) => (
										<Popover.Button
											key={key}
											as="div"
											className={`bg-transparent p-0 w-full inline-block`}
										>
											<div
												className={`flex items-center border-bottom list-gray py-3 px-[1rem] ${user.id === account.id &&
													"bg-active"
													}`}
											>
												<img
													className="mr-4 object-cover rounded-full w-[38px] h-[38px]"
													src={
														account.profile_pic
															? process.env.REACT_APP_AWS_URl +
															"/" +
															account.profile_pic
															: avatar
													}
													alt={account.name}
												/>
												<div>
													<h6 className="font-medium text-[13px] mb-1">
														{account.name}
													</h6>
												</div>
												{user.id !== account.id ? (
													<button
														className="px-[1rem] leading-7 border-0 text-[14px] rounded-[8px] bg-primary font-medium text-white hover:opacity-80 whitespace-nowrap tracking-wider h-[35px] inlie-flex item-center justify-center ml-auto"
														onClick={() =>
															this.switchAccount(account.id)
														}
													>
														Switch workspace
													</button>
												) : null}
											</div>
										</Popover.Button>
									))}
							</div>

							<div
								className={`flex items-center ${refreshData.is_main
										? "justify-between"
										: "justify-end"
									}  mt-4 px-[1rem]`}
							>
								{refreshData.is_main &&
									((refreshData.offer?.sub_account > 0 || refreshData.offer?.sub_account == -1) ? (
										<button
											className="flex items-center gap-x-3 leading-7 border-0 text-[14px] rounded-[8px] font-medium text-[#DBD2BD] hover:opacity-80 whitespace-nowrap h-[40px]"
											onClick={() =>
												this.addbrandRef.current.openAddbrand()
											}
										>
											<PiCirclesThreePlus color="#DBD2BD" size={22}  /> Add new workspace
										</button>
									) : (
										<Popover.Button
											as="div"
											className="bg-transparent p-0 w-auto inline-block"
										>
											{this.props.role === "brand" && (
												<Tooltip
													trigger={
														<NavLink
															className="px-4 py-[0.5rem] leading-6 bg-[#f4f4f5] border-[1px] dark border-[#f4f4f5] shadow inline-flex items-center justify-center whitespace-nowrap text-[12px] font-medium hover:opacity-80"
															to="/billing"
														>
															<FiPlus size={16} className="mr-1" />
															Add sub-account
															<MdLock className="ml-1" size={16} />
														</NavLink>
													}
													tooltipText="Please upgrade your subscription to add
																sub-accounts"
													placement="top-left"
												/>
											)}
										</Popover.Button>
									))}
								<Popover.Button
									as="div"
									className="bg-transparent p-0 inline-block w-auto"
								>
									<button
										className="leading-7 border-0 flex items-center gap-x-3 text-[14px] rounded-[8px] font-medium text-[#DBD2BD] hover:opacity-80 whitespace-nowrap h-[40px]"
										onClick={this.props.handleLogoutSubmit}
									>
										<PiDoorOpen color="#DBD2BD" size={22}  /> Log out
									</button>
								</Popover.Button>
							</div>
						</div>
					)}
                  </Popover.Panel>
              </Transition>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: localStorage.getItem("isLogin") ? JSON.parse(localStorage.user) : "",
    role: localStorage.role,
    currentLoggedUser: state.HeaderReducer.currentLoggedUser,
    planName: state.HeaderReducer.planName,
    remainingCredits: state.HeaderReducer.remainingCredits,
    refreshData: state.HeaderReducer.refreshData,
    subAccounts: state.subAccount.data,
    mainAccount: state.subAccount.main,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("@store/redux/SubAccountRedux");
  return {
    handleLogoutSubmit: () => dispatch({ type: HANDLE_LOGOUT_SUBMIT }),
    currentLoggedInUser: (query) =>
      dispatch(headerActions.currentLoggedInUser(query)),
    refreshReports: () => dispatch(headerActions.refreshReports()),
    switchAccount: (data) => {
      actions.switchBrandAccount(dispatch, data);
    },
    fetchInfluencerSubAccount: (data) => {
      actions.fetchInfluencerSubAccount(dispatch, data);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandHeader);
