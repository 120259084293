import { Component } from "react";
import { connect } from "react-redux";
import AnalyzerFilter from "@components/BrandInfluencerAnalyzer/Filters/AnalyzerFilter";
import AnalyzerItems from "@components/BrandInfluencerAnalyzer/Items/AnalyzerItems";
import { Helmet } from "react-helmet";
import SubHeader from "@components/Header/SubHeader";

class BrandInfluencersAnalyzerTiktok extends Component {
  constructor(props) {
    super(props);
    this.page = 0;
  }

  componentDidMount() {
    const { handlePlatform } = this.props;
    handlePlatform("tiktok");
    this.loadMore();
  }

  loadMore = (page = this.props.currentPage) => {
    const { analyzedUsers, sort_query } = this.props;
    let query = {
      platform: "tiktok",
      sort_query: sort_query,
    };
    this.page = page;
    analyzedUsers(this.page, query);
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Analyzer | Tiktok | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <SubHeader title={'Analyze Influencers'} />
        <div className="mb-12 pt-6">
          <AnalyzerFilter />
          <AnalyzerItems loadMore={this.loadMore} />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ influencerAnalyzer }) => {
  return {
    sort_query: influencerAnalyzer.sort_query,
    currentPage: influencerAnalyzer.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("@store/redux/InfluencerAnalyzerRedux");
  return {
    handlePlatform: (data) => {
      actions.handlePlatform(dispatch, data);
    },
    analyzedUsers: (page, data) => {
      actions.analyzedUsers(dispatch, page, data);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandInfluencersAnalyzerTiktok);
